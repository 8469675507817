import React from "react";
import Chip from "@material-ui/core/Chip";
import classnames from "classnames";
import "./dealInfo.css";

export default function ({ companyInfo }) {
  return (
    <div>
      <h4>Deal Info</h4>
      <p className="text-grey text-sm">Business details and application</p>
      <div className="review-content-card">
        <table className="table-fixed w-100 ">
          <tbody>
            <tr className="border-bottom border-gray-100 border-t">
              <td className="py-4 font-bold text-sm">Business Name</td>
              <td className="py-4 text-sm">{companyInfo.company_name}</td>
            </tr>
            <tr className="border-bottom border-gray border-t">
              <td className="w-20 py-4 font-bold text-sm">Website</td>
              <td className="text-sm py-4">
                {companyInfo.company_website &&
                  companyInfo.company_website.length !== 0 && (
                    <a
                      href={
                        companyInfo.company_website.includes("http")
                          ? companyInfo.company_website
                          : `http://${companyInfo.company_website}`
                      }
                      target="_blank"
                    >
                      {companyInfo.company_website}
                    </a>
                  )}
              </td>
            </tr>
            <tr className="border-bottom border-gray border-t">
              <td className="w-20 py-4 font-bold text-sm">Location</td>
              <td className="text-sm py-4">
                {companyInfo.company_website &&
                  companyInfo.company_website.length !== 0 &&
                  companyInfo.formatted_address &&
                  companyInfo.formatted_address.length !== 0}
                {companyInfo.formatted_address &&
                  companyInfo.formatted_address.length !== 0 && (
                    <p className="text-sm" style={{ display: "inline" }}>
                      {companyInfo.city}, {companyInfo.country}
                    </p>
                  )}
              </td>
            </tr>
            <tr className="border-bottom border-gray border-t p-3">
              <td className="w-20 py-4 font-bold text-sm align-top">
                Description
              </td>
              <td className="text-sm py-4">{companyInfo.short_bio}</td>
            </tr>
            <tr className="border-bottom border-gray border-t p-3">
              <td className="w-20 py-2 font-bold text-sm align-top py-4">
                Business Models
              </td>
              <td className="text-sm">
                <Chip
                  variant="outlined"
                  className={classnames("exploreTag", "blueHl")}
                  color={"primary"}
                  size="small"
                  label={companyInfo.business_model}
                />
              </td>
            </tr>
            <tr>
              <td className="w-20 font-bold text-sm align-top py-4">
                Industries Disrupted
              </td>
              <td className="text-sm py-4">
                <div className="allTags">
                  {companyInfo.industry.map((val) => (
                    <Chip
                      variant="outlined"
                      className={classnames("exploreTag", "greenHl")}
                      color={"primary"}
                      size="small"
                      label={val}
                    />
                  ))}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
