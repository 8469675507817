import React, { useState } from "react";
import "./reports.css";
import { TypeAnimation } from "react-type-animation";

export default function StartupScore({
  companyInfo,
  applicationObject,
  opportunityInfo,
  handleCreditChange,
  updateCompanyData,
}) {
  const [startupScore, setStartupScore] = useState(
    companyInfo.StartupScore || null,
  );
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [startupScoreError, setStartupScoreError] = useState("");
  const [isNewlyGenerated, setIsNewlyGenerated] = useState(false);
  const [animationComplete, setAnimationComplete] = useState({});

  const handleStartupScore = async () => {
    const { pitchdeckUrl } = companyInfo;
    const companyId = companyInfo.applicant_company;

    if (!applicationObject && !pitchdeckUrl) {
      setStartupScoreError("Application Info and Pitchdeck URL are missing.");
      return;
    }

    if (opportunityInfo.credits < 1) {
      setStartupScoreError(
        "You do not have enough credits to generate a startup score.",
      );
      return;
    }

    setIsSummarizing(true);
    setStartupScoreError(""); // Clear previous errors

    try {
      const requestBody = {};

      const hasValidAnswers =
        applicationObject &&
        Object.values(applicationObject).some(
          (answer) => typeof answer === "string" && answer.trim() !== "",
        );

      if (hasValidAnswers) {
        requestBody.applicationObject = applicationObject;
      }

      if (pitchdeckUrl) {
        requestBody.pitchdeckUrl = pitchdeckUrl;
      }

      const response = await fetch(
        `/fetch_company_score?company_id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        },
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Response from server:", data);

      handleCreditChange(-1);

      setStartupScore(data.analysis);
      setIsNewlyGenerated(true);
      setAnimationComplete({});
      updateCompanyData(companyId, { StartupScore: data.analysis });
    } catch (error) {
      console.error("Error fetching company score:", error);
      setStartupScoreError("Error fetching company score.");
    } finally {
      setIsSummarizing(false);
    }
  };

  const handleAnimationComplete = (category) => {
    setAnimationComplete((prevState) => ({
      ...prevState,
      [category]: true,
    }));

    // Check if all animations are complete
    const allComplete = Object.values({
      ...animationComplete,
      [category]: true,
    }).every((value) => value);

    if (allComplete) {
      setIsNewlyGenerated(false);
    }
  };

  const renderTypingAnimation = (text, category) => (
    <TypeAnimation
      sequence={[text, () => handleAnimationComplete(category)]}
      wrapper="span"
      cursor={true}
      speed={85}
      repeat={0}
      style={{ whiteSpace: "pre-line" }}
    />
  );

  const renderScoreDetails = () => {
    if (startupScoreError) {
      return <p className="error">{startupScoreError}</p>;
    }

    if (!startupScore || Object.keys(startupScore).length === 0) return null;

    const overallCategory = "Overall";
    const categories = Object.keys(startupScore).filter(
      (category) => category.toLowerCase() !== "overall",
    );

    return (
      <div className="report-container">
        <h1 className="report-title">Startup Score</h1>

        {startupScore[overallCategory] && (
          <div className="overall-summary">
            <h2 className="overall-summary-title">
              Overall Score:{" "}
              <span className="overall-score">
                {startupScore[overallCategory].Score
                  ? startupScore[overallCategory].Score.toFixed(1)
                  : "N/A"}
              </span>
            </h2>
            <div className="overall-score-container">
              <div
                className="overall-score-bar"
                style={{
                  width: `${(startupScore[overallCategory].Score || 0) * 10}%`,
                }}
              ></div>
            </div>
            <p className="summary-text">
              {isNewlyGenerated
                ? renderTypingAnimation(
                    startupScore[overallCategory].Reason ||
                      "No overall summary available.",
                    "overall",
                  )
                : startupScore[overallCategory].Reason ||
                  "No overall summary available."}
            </p>
            {startupScore[overallCategory].DataIntegrity && (
              <div className="summary-text">
                <h3 className="summary-text">
                  Data Integrity:{" "}
                  <span className="summary-text">
                    {startupScore[overallCategory].DataIntegrity.Score
                      ? `${Math.round(startupScore[overallCategory].DataIntegrity.Score)}%`
                      : "N/A"}
                  </span>
                </h3>
                <p className="summary-text">
                  {isNewlyGenerated
                    ? renderTypingAnimation(
                        startupScore[overallCategory].DataIntegrity.Reason ||
                          "No data integrity reason provided.",
                        "overallDataIntegrity",
                      )
                    : startupScore[overallCategory].DataIntegrity.Reason ||
                      "No data integrity reason provided."}
                </p>
              </div>
            )}
          </div>
        )}

        {categories.map((category, index) => (
          <div key={index} className="overall-summary">
            <h2 className="overall-summary-title">
              {category}:{" "}
              <span className="overall-score">
                {startupScore[category].Score
                  ? startupScore[category].Score.toFixed(1)
                  : "N/A"}
              </span>
            </h2>
            <div className="overall-score-container">
              <div
                className="overall-score-bar"
                style={{
                  width: `${(startupScore[category].Score || 0) * 10}%`,
                }}
              ></div>
            </div>
            <p className="summary-text">
              {isNewlyGenerated
                ? renderTypingAnimation(
                    startupScore[category].Reason || "No reason provided.",
                    category,
                  )
                : startupScore[category].Reason || "No reason provided."}
            </p>
            {startupScore[category].DataIntegrity && (
              <div className="summary-text">
                <h3 className="summary-text">
                  Data Integrity:{" "}
                  <span className="summary-text">
                    {startupScore[category].DataIntegrity.Score
                      ? `${Math.round(startupScore[category].DataIntegrity.Score)}%`
                      : "N/A"}
                  </span>
                </h3>
                <p className="summary-text">
                  {isNewlyGenerated
                    ? renderTypingAnimation(
                        startupScore[category].DataIntegrity.Reason ||
                          "No data integrity reason provided.",
                        `${category}DataIntegrity`,
                      )
                    : startupScore[category].DataIntegrity.Reason ||
                      "No data integrity reason provided."}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="px-3">
      <h4>Startup Score</h4>
      <p className="text-grey text-sm">
        Generate a Startup Score using the patent pending StartupFuel Scoring
        DiligenceGPT Model.
      </p>
      <>
        <div className="flex justify-between">
          <div className="mr-4">
            <button
              onClick={handleStartupScore}
              disabled={isSummarizing}
              className="summarize-button"
            >
              {isSummarizing ? "Generating..." : "Generate Startup Score"}
            </button>
          </div>
        </div>
        {renderScoreDetails()}
      </>
    </div>
  );
}
