import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import default_pic from "../../img/default_company_pic.png";
import default_background from "../../img/bg.png";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Navbar,
  Nav,
  Row,
  Col,
  Card,
  Button,
  ButtonToolbar,
  Container,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
  base_profile_fields,
  goals,
  investor_goals,
} from "../../boilerplate/list.json";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});
const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: "#eeeeee",
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#54a2d7",
  },
})(LinearProgress);
class CustomizedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_field: 0,
      file: "",
      imagePreviewUrl: "",
      error_msg: "",
    };
  }
  changeSelect = (e, number) => {
    e.preventDefault();
    this.setState({
      selected_field: number,
      file: "",
      imagePreviewUrl: "",
      error_msg: "",
    });
  };
  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleSkip = (e) => {
    e.preventDefault();
    this.setState({
      selected_field: this.state.selected_field + 1,
      file: "",
      imagePreviewUrl: "",
      error_msg: "",
    });
  };
  handleDateChange = (date) => {
    this.setState({
      date_of_inception: date,
    });
  };
  handleInputSubmit = (e, field) => {
    e.preventDefault();
    if (
      this.state[field] !== undefined &&
      this.state[field] !== null &&
      this.state[field].length > 0
    ) {
      if (
        base_profile_fields[field].special !== undefined &&
        base_profile_fields[field].special === "website"
      ) {
        let lastDotPos = this.state[field].lastIndexOf(".");
        let httpPos = this.state[field].indexOf("http://");
        let httpsPos = this.state[field].indexOf("https://");
        if (
          !(
            (httpPos !== -1 || httpsPos !== -1) &&
            lastDotPos > 2 &&
            this.state[field].length - lastDotPos > 2
          )
        ) {
          this.setState({
            error_msg: "Please use the following format: http://website.com",
          });
        } else {
          this.props.handleProfileCompletenessSave(e, field, this.state[field]);
        }
      } else if (
        base_profile_fields[field].special !== undefined &&
        base_profile_fields[field].special === "email"
      ) {
        let lastAtPos = this.state[field].lastIndexOf("@");
        let lastDotPos = this.state[field].lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            this.state[field].indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            this.state[field].length - lastDotPos > 2
          )
        ) {
          this.setState({
            error_msg:
              " Email is not valid, please structure it like 'user@example.com'",
          });
        } else {
          this.props.handleProfileCompletenessSave(e, field, this.state[field]);
        }
      } else {
        this.props.handleProfileCompletenessSave(e, field, this.state[field]);
      }
    } else if (
      this.state[field] !== undefined &&
      this.state[field] !== null &&
      base_profile_fields[field].type === "date"
    ) {
      this.props.handleProfileCompletenessSave(e, field, this.state[field]);
    } else {
      this.setState({
        error_msg: "Please submit a value",
      });
    }
  };
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
    this.props.handleFileUpload(e);
  }
  render() {
    const { classes } = this.props;
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img style={{ height: "100%" }} src={imagePreviewUrl} />;
    } else {
      $imagePreview = (
        <div className="previewText">Please select an Image for Preview</div>
      );
    }
    return (
      <div>
        <div style={{ width: "100%" }}>
          <div className="" style={{ width: "100%" }}>
            <Card.Body className="video-card-body">
              <br />
              <button
                type="button"
                onClick={(event) =>
                  this.props.handleEdit("show_profile_completeness")
                }
                class="close"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <Card.Title>Complete your Profile</Card.Title>
              <br />
              <h6 style={{ textAlign: "left" }}>
                Profile: {this.props.profile_completeness}%
              </h6>
              <BorderLinearProgress
                className={classes.margin}
                variant="determinate"
                color="secondary"
                value={this.props.profile_completeness}
              />
              <br />

                {Object.keys(base_profile_fields).map((field, i) => (
                  <div>
                    {base_profile_fields[field].type !== "file" &&
                      base_profile_fields[field].type !== "date" &&
                      this.props[field] !== null &&
                      this.props[field].length === 0 && (
                        <div>
                          <a onClick={(event) => this.changeSelect(event, i)}>
                            <div className="profile-completeness-items py-3">
                              {" "}
                              <span style={{ float: "left" }}>
                                {base_profile_fields[field].header}
                              </span>{" "}
                              <span style={{ float: "right" }}>
                                {base_profile_fields[field].percent} %
                              </span>{" "}
                            </div>
                          </a>
                          {this.state.selected_field === i && (
                            <div className="py-3">
                              <p>
                                {base_profile_fields[field].sub_text}
                              </p>
                              <div>
                                {base_profile_fields[field].type ===
                                  "textarea" && (
                                  <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    style={{ width: "100%" }}
                                    rows="4"
                                    value={this.state[field]}
                                    name={field}
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                  />
                                )}
                                {base_profile_fields[field].type === "text" && (
                                  <FormControl
                                    type="text"
                                    value={this.state[field]}
                                    name={field}
                                    onChange={this.handleChange}
                                    className="form-control mb-3"
                                  />
                                )}
                                {base_profile_fields[field].type ===
                                  "dropdown" &&
                                  (this.props.company_type[0] === "Startup" ||
                                    this.props.company_type[0] ===
                                      "Tech Startup") && (
                                    <TextField
                                      id="standard-select-currency"
                                      select
                                      label="Select"
                                      className={classes.textField}
                                      value={this.state[field]}
                                      name={field}
                                      style={{ width: "200px" }}
                                      onChange={this.handleChange}
                                      SelectProps={{
                                        MenuProps: { className: classes.menu },
                                      }}
                                      margin="normal"
                                    >
                                      {goals.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  )}
                                {base_profile_fields[field].type ===
                                  "dropdown" &&
                                  (this.props.company_type[0] !== "Startup" ||
                                    this.props.company_type[0] !==
                                      "Tech Startup") && (
                                    <TextField
                                      id="standard-select-currency"
                                      select
                                      label="Select"
                                      className={classes.textField}
                                      value={this.state[field]}
                                      name={field}
                                      style={{ width: "200px" }}
                                      onChange={this.handleChange}
                                      SelectProps={{
                                        MenuProps: { className: classes.menu },
                                      }}
                                      margin="normal"
                                    >
                                      {investor_goals.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  )}
                                <Button
                                  variant="contained"
                                  name="showEditFour"
                                  onClick={this.handleSkip}
                                  style={{
                                    backgroundColor: "grey",
                                    textAlign: "center",
                                  }}
                                  className={classes.button}
                                >
                                  Skip
                                </Button>
                                <Button
                                  variant="contained"
                                  name="showEditFour"
                                  onClick={(event) =>
                                    this.handleInputSubmit(event, field)
                                  }
                                  style={{
                                    backgroundColor: "#007bff",
                                    textAlign: "center",
                                  }}
                                  className={classes.button}
                                >
                                  Save
                                </Button>
                                <span style={{ color: "red" }}>
                                  {this.state.error_msg}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    {this.props[field] !== null &&
                      base_profile_fields[field].type === "file" &&
                      !this.props.company_files.some(
                        (file) =>
                          file.file_type ===
                          base_profile_fields[field].file_type
                      ) && (
                        <div>
                          <a onClick={(event) => this.changeSelect(event, i)}>
                            <li>
                              {" "}
                              <span style={{ float: "left" }}>
                                {base_profile_fields[field].header}
                              </span>{" "}
                              <span style={{ float: "right" }}>
                                {base_profile_fields[field].percent} %
                              </span>{" "}
                            </li>
                          </a>
                          {this.state.selected_field === i && (
                            <div>
                              <Card.Title>
                                {base_profile_fields[field].sub_text}
                              </Card.Title>
                              <div class="container">
                                <div className="form-gorup">
                                  <FormLabel className="control-label">
                                    Profile Photo - Please upload a photo with
                                    equal dimensions. (ex 400 x 400)
                                  </FormLabel>
                                  <FormControl
                                    type="file"
                                    data-file-type={
                                      base_profile_fields[field].dataFileType
                                    }
                                    placeholder="Profile Photo"
                                    accept=".gif,.jpg,.jpeg,.png"
                                    name={base_profile_fields[field].name}
                                    onChange={(e) => this._handleImageChange(e)}
                                    className="form-control mb-3"
                                  />
                                </div>
                                <div className="previewComponent">
                                  <div
                                    className="imgPreview"
                                    style={{ margin: "unset", width: "unset" }}
                                  >
                                    {$imagePreview}
                                  </div>
                                </div>
                                <Button
                                  variant="contained"
                                  name="showEditFour"
                                  onClick={this.handleSkip}
                                  style={{
                                    backgroundColor: "grey",
                                    textAlign: "center",
                                  }}
                                  className={classes.button}
                                >
                                  Skip
                                </Button>
                                <Button
                                  variant="contained"
                                  name="showEditFour"
                                  onClick={this.props.handleInputSubmit}
                                  style={{
                                    backgroundColor: "#007bff",
                                    textAlign: "center",
                                  }}
                                  className={classes.button}
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    {base_profile_fields[field].type === "date" &&
                      (this.props[field] === null ||
                        this.props[field].length === 0) && (
                        <div>
                          <a onClick={(event) => this.changeSelect(event, i)}>
                            <li>
                              {" "}
                              <span style={{ float: "left" }}>
                                {base_profile_fields[field].header}
                              </span>{" "}
                              <span style={{ float: "right" }}>
                                {base_profile_fields[field].percent} %
                              </span>{" "}
                            </li>
                          </a>
                          {this.state.selected_field === i && (
                            <div>
                              <Card.Title>
                                {base_profile_fields[field].sub_text}
                              </Card.Title>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  margin="normal"
                                  label="Date picker"
                                  name={field}
                                  value={this.state[field]}
                                  onChange={this.handleDateChange}
                                />
                              </MuiPickersUtilsProvider>
                              <Button
                                variant="contained"
                                name="showEditFour"
                                onClick={this.handleSkip}
                                style={{
                                  backgroundColor: "grey",
                                  textAlign: "center",
                                }}
                                className={classes.button}
                              >
                                Skip
                              </Button>
                              <Button
                                variant="contained"
                                name="showEditFour"
                                onClick={(event) =>
                                  this.handleInputSubmit(event, field)
                                }
                                style={{
                                  backgroundColor: "#007bff",
                                  textAlign: "center",
                                }}
                                className={classes.button}
                              >
                                Save
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                ))}

            </Card.Body>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}
CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);
