/* (2019-06-10) - This page is actually a page of the SIR/ApplicationRoom
It doesnt contain much just houses the components.
Its components and the ability to display & manage the team of judges of the SIR
can be found in/application_room/components/team.
The sidebar contains access to the other pages associate with the SIR.
*/
import React, { Component } from 'react';
import { AppContext } from '../App'
import PropTypes from 'prop-types';
import axios from 'axios'
import URL from 'url-parse';
import default_pic from '../img/default-avatar.png'
import default_background from '../img/bg.png'


import SiteTitle from './components/site_title.js'
import { Sign_In, Register, Forgot_Password, Alert_Message_To_User } from './components/popups'
import Navigation from './components/navbar_pb'
import Team from "./application_room/components/team/team"
import TeamMgmt from "./application_room/components/team_management/team";
import SideBar from "./application_room/components/Sidebar"
import TopNav from "./application_room/components/topNav"
import ArgonPitchBoxMenu from './application_room/components/topNav';

class Applicant_Room_Team_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return (
      <Applicant_Room_Team
        {...this.props}
        {...this.context} />
    )
  }
}

class Applicant_Room_Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      judges: [],
      opportunity_owner: "",
      show_page: true,
      hasAccess: false,
      loaded: false,
      opportunity_data: {},
    }
  }
  componentDidMount() {
    axios.get(`/fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`)
      .then((response) => {
        this.setState({
          opportunity_data: response.data.result,
        })
      })
    axios.get(`fetch_judges_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`)
      .then((response) => {
        this.setState({
          judges: response.data.judges,
          opportunity_owner: response.data.owner,
          access_ids: response.data.access_ids,
        }, () => {
          console.log("response");
          console.log(response);

          if (this.state.access_ids.includes(this.props.user._id)||this.props.user.email==="trevor@pangeafirm.com"||this.props.user.email==="ash@startupfuel.com") {

            this.setState({
              hasAccess: true
            })

          }
          this.setState({
            loaded: true
          })


        })
      })
      axios.get(`fetch_judges_for_opportunity_simple?opportunity_id=${this.state.url.query.opportunity_id}`)
      .then((response) => {
        this.setState({
          judges_with_reviews: response.data.opp_judges
        })
      })


  }
  refresh_judges = () => {
    axios.get(`fetch_judges_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`)
      .then((response) => {
        this.setState({
          judges: response.data.judges,
          opportunity_owner: response.data.owner,
          access_ids: response.data.access_ids,
        })
      })

  }
  getAvatarUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter(file => {
        return file.file_type === file_type
      })
      if (new_arr.length !== 0) {

        return new_arr[0].file_url
      } else {
        return default_pic
      }
    } else {
      return default_pic
    }
  }
  render() {
    return (
      <div className='__application-room-main'>
        <Navigation />
        <SiteTitle />
        <Sign_In />
        <Register />
        <Forgot_Password />
        <SideBar
            user={this.props.user}
            {...this.state} />
          <Team
            getAvatarUrl={this.getAvatarUrl}
            refresh_judges={this.refresh_judges}
            opportunity_name={this.state.opportunity_data.opportunity_name}
            {...this.state}
            user={ this.props.user } />
            <TeamMgmt
              getAvatarUrl={this.getAvatarUrl}
              refresh_judges={this.refresh_judges}
              opportunity_name={this.state.opportunity_data.opportunity_name}
              {...this.state} 
              user={ this.props.user }/>
      </div>
    );
  }
}

Applicant_Room_Team_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default Applicant_Room_Team_Container;
