/* (2019-06-11) - Contains cards used throughout the site, some are used,
some are not used.
*/
import React from "react";
import { stage_list } from "../../boilerplate/list.json";


export const Profile_Card = (props) => (
  <div class="card profile-card-2 my-5">
    <div class="card-img-block"></div>
    <div class="card-body pt-4 text-center">
      {props.userIsProfileOwner && (
        <a className="top-right" onClick={props.handleEditPopup}>
          <i class="fa fa-lg fa-pencil" aria-hidden="true"></i>
        </a>
      )}
      <img
        src={props.getAvatarUrl(props.company_files, "company avatar")}
        alt="profile-image"
        class="profile2 mb-2"
      />
      <h2 class="card-title mt-3">{props.company_name}</h2>
      <a href={props.website}>{props.website || ""}</a>
      <hr />
      <div className="row mt-3">
        <div className="col">
          <p>
            Location:
            <br /> <a class="badge badge-light m-1 text-white">#{props.city}</a>
            <a class="badge badge-light m-1 text-white">#{props.province}</a>
            <a class="badge badge-light m-1 text-white">#{props.country}</a>
          </p>
        </div>
        <div className="col">
          <p>
            Stage:
            <br />{" "}
            <a class="badge badge-light m-1 text-white">
              #{props.getStage(props.stage, stage_list)}
            </a>
          </p>
        </div>
        <div class="col-12">
          <p>
            Industry:
            <br />
            {props.industry.map((industry) => (
              <div>
                <a class="badge badge-light m-1 text-white text-white">
                  #{industry}
                </a>
                <br />
              </div>
            ))}
          </p>
        </div>
        <p></p>
      </div>
    </div>
  </div>
);

export const User_Profile_Card = (props) => (
  <div class="card profile-card-2 my-4">
    <div class="card-img-block"></div>
    <div class="card-body pt-4 text-center">
      <div className="logo" style={{ display: "unset" }}>
        <div className="profilePic-container">
          <img
            style={{ height: "100%" }}
            src={props.getAvatarUrl(props.user.files, "user avatar")}
          />
          <div>
            <div className="profilePic-overlay"></div>
            <span
              onClick={props.handleUserProfilePhotoEditPopup}
              className="profilePic-span"
            >
              Edit Profile Picture
            </span>
          </div>
        </div>
        <div style={{ background: "black" }}></div>
      </div>
      <h2 class="card-title mt-3">
        {props.user.first_name} {props.user.last_name}
      </h2>
      <hr />
      {props.companies_owned !== null && props.companies_owned !== [0] && (
        <h3>
          <span class="helper-capitalize">{props.companies_owned[0].type}</span> @{" "}
          {props.companies_owned[1][0].company_name}
        </h3>
      )}
    </div>
  </div>
);

export const Explore_Profile_Card = (props) => (
  <div className="col-xl-3 col-md-4 col-sm-6">
    <div class="card card-personal mb-4">
      <div class="view">
        <div
          class="card-img-top img"
          style={{
            backgroundImage: `url(${props.getAvatarUrl(
              props.company_files,
              "company background"
            )})`,
          }}
          alt="Card image cap"
        />
      </div>
      <div class="card-body clearfix text-center textbody">
        <img
          src={props.getAvatarUrl(props.company_files, "company avatar")}
          alt="profile-image"
          class="profile1"
        />
        <a>
          <h4 class="card-title mb-2 mt-4">{props.company_name}</h4>
        </a>
        <hr />
        <div class="chipbody">
          <a class="card-meta mr-3 badge badge-light m-1">
            #{props.business_model}
          </a>
          <a class="card-meta badge badge-light m-1">#{props.industry}</a>
          <a class="card-meta badge badge-light m-1">
            #{props.getStage(props.stage, stage_list)}
          </a>
          <a class="card-meta badge badge-light m-1">#{props.company_type}</a>
        </div>
        <p class="card-text bio mt-3">{props.short_bio}</p>
        <hr />
        <a href={"/profile?company_id=" + props._id}>
          <button class="btn btn-primary btn-sm m-2"> Visit </button>
        </a>
      </div>
    </div>
  </div>
);

export const Explore_Merge_Profile_Card = (props) => (
  <div className="col-xl-3 col-md-4 col-sm-6">
    <div class="card card-personal mb-4">
      <div class="view">
        <div
          class="card-img-top img"
          style={{
            backgroundImage: `url(${props.getAvatarUrl(
              props.company_files,
              "company background"
            )})`,
          }}
          alt="Card image cap"
        />
      </div>
      <div class="card-body clearfix text-center textbody">
        <img
          src={props.getAvatarUrl(props.company_files, "company avatar")}
          alt="profile-image"
          class="profile1"
        />
        <a>
          <h4 class="card-title mb-2 mt-4">{props.company_name}</h4>
        </a>
        <hr />
        <div class="chipbody">
          <a class="card-meta mr-3 badge badge-light m-1">
            #{props.business_model}
          </a>
          <a class="card-meta badge badge-light m-1">#{props.industry}</a>
          <a class="card-meta badge badge-light m-1">
            #{props.getStage(props.stage, stage_list)}
          </a>
          <a class="card-meta badge badge-light m-1">#{props.company_type}</a>
        </div>
        <p class="card-text bio mt-3">{props.short_bio}</p>
        <hr />
        <a href={"/profile_merge?company_id=" + props._id}>
          <button class="btn btn-primary btn-sm m-2"> Visit </button>
        </a>
      </div>
    </div>
  </div>
);

export const Opportunity_Card = (props) => (
  <div className="col-md-4 col-sm-6">
    <div class="card card-personal mb-4">
      <div class="view">
        <div
          class="card-img-top img"
          style={{ backgroundImage: `url(${props.background_cover})` }}
          alt="Card image cap"
        />
        <a href="#">
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>
      <div class="card-body clearfix text-center">
        <img alt="profile-image" class="profile1" />
        <a>
          <h4 class="card-title mb-2 mt-4 cardmain">
            {props.opportunity_name}
          </h4>
        </a>
        <hr />
        <a class="card-meta mr-3 badge badge-light m-1">#{props.location}</a>
        <a class="card-meta badge badge-light m-1">{props.website_url}</a>
        <p class="card-text bio mt-3">{props.opportunity_description}</p>
        <hr />
        <a href={"/profile?company_id=" + props._id}>
          <button class="btn btn-primary btn-sm m-2"> Visit </button>
        </a>
        {/*<button type="submit" class="btn btn-primary btn-sm waves-effect waves-light m-2">Follow</button>
                                        <button type="submit" class="btn btn-primary btn-sm waves-effect waves-light m-2">Message</button>*/}
      </div>
    </div>
  </div>
);
