import React, { useState } from "react";
import "./reports.css";
import { TypeAnimation } from "react-type-animation";

export default function Summary({
  companyInfo,
  applicationObject,
  updateCompanyData,
  handleCreditChange,
}) {
  const [summary, setSummary] = useState(companyInfo.summary || {});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewlyGenerated, setIsNewlyGenerated] = useState(false);
  const [animationComplete, setAnimationComplete] = useState({});

  const sections = ["Idea", "Tech", "Team", "Marketing", "Traction"];
  const hasSummaryContent = Object.keys(summary).some(
    (section) => summary[section],
  );

  const handleSummarize = async () => {
    setLoading(true);
    setError(null);

    const companyId = companyInfo.applicant_company;

    try {
      const requestBody = {};

      const hasValidAnswers =
        applicationObject &&
        Object.values(applicationObject).some(
          (answer) => typeof answer === "string" && answer.trim() !== "",
        );

      if (hasValidAnswers) {
        requestBody.applicationObject = applicationObject;
      }

      const response = await fetch(
        `/fetch_company_summary?company_id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch summary.");
      }

      const data = await response.json();
      setSummary(data.summary);
      updateCompanyData(companyId, { summary: data.summary });
      handleCreditChange(-1);
      setIsNewlyGenerated(true);
      setAnimationComplete({});
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAnimationComplete = (section) => {
    setAnimationComplete((prevState) => ({
      ...prevState,
      [section]: true,
    }));

    const allComplete = Object.values({
      ...animationComplete,
      [section]: true,
    }).every((value) => value);

    if (allComplete) {
      setIsNewlyGenerated(false);
    }
  };

  const renderTypingAnimation = (text, section) => (
    <TypeAnimation
      sequence={[text, () => handleAnimationComplete(section)]}
      wrapper="span"
      cursor={true}
      speed={85}
      repeat={0}
      style={{ whiteSpace: "pre-line" }}
    />
  );

  return (
    <div className="px-3">
      <h4>Deal Summary</h4>
      <p className="text-grey text-sm">
        Generate a Summary of the Deal using the patent pending Deal
        Summarization DiligenceGPT Model.
      </p>
      <button
        onClick={handleSummarize}
        disabled={loading}
        className="summarize-button"
        style={{ marginBottom: "20px" }}
      >
        {loading
          ? "Summarizing..."
          : hasSummaryContent
            ? "Regenerate Summary"
            : "Generate Summary"}
      </button>

      {error && <p className="error-text">{error}</p>}

      {hasSummaryContent && (
        <div className="report-container">
          <h1 className="report-title">Deal Summary</h1>

          {sections.map((section) => (
            <div key={section} className="overall-summary">
              <h2 className="category-title">{section}</h2>
              <p className="summary-text">
                {isNewlyGenerated
                  ? renderTypingAnimation(
                      summary[section] || `No summary available for ${section}`,
                      section,
                    )
                  : summary[section] || `No summary available for ${section}`}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
