import React, { useState } from "react";
import classNames from "classnames";
import "./dealInfo.css";
import { Delete, CloudDownload, Visibility } from "@material-ui/icons";
import FileViewerPopup from "../FileViewer";
import {
  document_labels,
  file_type_mapping,
} from "../../../../../boilerplate/list.json";

import AddFiles from "../multipleDocumentUpload"; // Import AddFiles component

const DataRoomComponent = ({ files, getFiles, deleteFile, companyInfo }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal open/close

  const handleViewFile = (fileUrl) => {
    setSelectedFile(fileUrl);
  };

  const handleCloseViewer = () => {
    setSelectedFile(null);
  };

  const handleDelete = (fileType) => {
    console.log(`Delete request for file type: ${fileType}`);
    if (window.confirm("Are you sure you want to delete this file?")) {
      deleteFile(fileType);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
      <h4>Data Room</h4>
      <p className="text-grey text-sm">View/Modify Company Files</p>
      <div>
        <button className="add-files-button" onClick={handleOpenModal}>
          Add Files
        </button>
        <div className="review-content-card">
          {/* Check if files array is empty */}
          {files.length === 0 ? (
            <p className="text-grey text-sm">No files uploaded.</p>
          ) : (
            <table className="table-fixed w-100">
              <thead>
                <tr className="border-gray border-b">
                  <th className="p-2 text-left">File Type</th>
                  <th className="p-2 text-left">File Size</th>
                  <th className="p-2 text-left">Added On</th>
                  <th className="p-2 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => {
                  const isLastItem = index === files.length - 1;

                  // Find the key in the file_type_mapping object that corresponds to the file.file_type value
                  const originalFileType =
                    Object.keys(file_type_mapping).find(
                      (key) => file_type_mapping[key] === file.file_type,
                    ) || file.file_type; // Fallback to file.file_type if no match is found

                  return (
                    <tr
                      key={index}
                      className={classNames("border-gray border-t", {
                        "border-b": !isLastItem,
                      })}
                    >
                      <td className="py-4 text-sm">{originalFileType}</td>
                      <td className="py-4 text-sm">{file.file_size}</td>
                      <td className="py-4 text-sm">
                        {new Date(file.time_stamp).toLocaleString()}
                      </td>
                      <td className="py-4 text-sm">
                        <button
                          onClick={() => handleViewFile(file.file_url)}
                          className="data-room-icon-button"
                        >
                          <Visibility />
                        </button>
                        <a
                          href={file.file_url}
                          download
                          className="data-room-icon-button"
                        >
                          <CloudDownload />
                        </a>
                        <button
                          onClick={() => handleDelete(file.file_type)}
                          className="data-room-icon-button"
                        >
                          <Delete />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {selectedFile && (
        <FileViewerPopup fileUrl={selectedFile} onClose={handleCloseViewer} />
      )}
      {isModalOpen && (
        <AddFiles
          companyInfo={companyInfo}
          getFiles={getFiles}
          document_labels={document_labels}
          file_type_mapping={file_type_mapping}
          closeModal={handleCloseModal} // Pass closeModal handler
        />
      )}
    </div>
  );
};

export default DataRoomComponent;
