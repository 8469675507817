import React, { useState } from "react";
import "./uploadPopup.css"; // Import the CSS for styling
import axios from "axios"; // Axios for making API requests
import { Delete, CheckCircle } from "@material-ui/icons"; // Import icons

const AddFiles = ({
  companyInfo,
  document_labels,
  file_type_mapping,
  closeModal,
  getFiles,
  updateCompanyData,
}) => {
  const [selectedLabel, setSelectedLabel] = useState("");
  const [otherLabel, setOtherLabel] = useState("");
  const [fileList, setFileList] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  // Handle label change
  const handleLabelChange = (event) => {
    setSelectedLabel(event.target.value);
    if (event.target.value !== "Other") {
      setOtherLabel("");
    }
  };

  // Handle other label change
  const handleOtherLabelChange = (event) => {
    setOtherLabel(event.target.value);
  };

  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const label = selectedLabel === "Other" ? otherLabel : selectedLabel;
    const fileType =
      selectedLabel === "Other"
        ? otherLabel
        : file_type_mapping[label] || `company ${label}`;

    if (file && label) {
      const duplicate = fileList.some(
        (doc) => doc.file.name === file.name && doc.file_type === fileType,
      );
      if (duplicate) {
        setErrors({
          ...errors,
          duplicate: `Document type ${label} with this name already exists.`,
        });
        return;
      }

      const newDocument = {
        file,
        label,
        file_type: fileType,
        uploaded: false,
      };
      setFileList([...fileList, newDocument]); // Update the file list
      setSelectedLabel("");
      setOtherLabel("");
      setErrors({ ...errors, duplicate: null });
    } else {
      setErrors({ ...errors, label: "Please select a label." });
    }
  };

  // Handle file removal
  const handleRemoveFile = (index) => {
    setFileList((prevList) => prevList.filter((_, i) => i !== index));
  };

  // Upload file function
  const uploadFile = async (file, companyId, label, fileType) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", label.toLowerCase().replace(/ /g, "_"));
    formData.append("file_type", fileType);
    formData.append("object_ref", "company");
    formData.append("object_ref_value", companyId);

    try {
      const response = await axios.post(
        `/upload_file_secure?company_id=${companyId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      if (response.data.status === "success") {
        console.log(
          `Uploaded ${label} with URL: ${response.data.result.file_url}`,
        );

        // Call getFiles with the new file information
        getFiles({
          file_type: fileType,
          file_url: response.data.result.file_url,
          time_stamp: new Date().toISOString(),
          file_size: response.data.result.file_size,
        });

        return true;
      } else {
        console.error("Error uploading file:", response.data);
        return false;
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return false;
    }
  };

  // Submit handler
  const handleSubmit = async () => {
    setSubmitting(true);

    // Upload new files
    let allUploaded = true;
    for (let index = 0; index < fileList.length; index++) {
      const document = fileList[index];
      const uploaded = await uploadFile(
        document.file,
        companyInfo.applicant_company,
        document.label,
        document.file_type,
      );
      if (uploaded) {
        setFileList((prevList) =>
          prevList.map((doc, i) =>
            i === index ? { ...doc, uploaded: true } : doc,
          ),
        );
      } else {
        allUploaded = false;
      }
    }
    setSubmitting(false);

    // Close modal if all files are uploaded
    if (allUploaded) {
      closeModal();
    }
  };

  return (
    <div className="add-files-modal-wrapper">
      <div className="add-files-modal">
        <div className="modal-content">
          <button className="close-button" onClick={closeModal}>
            ✖
          </button>
          <h1>Upload Documents</h1>
          <div className="upload-container">
            <div className="upload-section">
              <label htmlFor="label-select">Select Document Type:</label>
              <select
                id="label-select"
                className="upload-select"
                value={selectedLabel}
                onChange={handleLabelChange}
                disabled={submitting}
              >
                <option value="" disabled>
                  Select a label
                </option>
                {document_labels.map((label) => (
                  <option key={label} value={label}>
                    {label}
                  </option>
                ))}
              </select>
              {errors.label && <span className="error">{errors.label}</span>}
              {selectedLabel === "Other" && (
                <input
                  type="text"
                  value={otherLabel}
                  onChange={handleOtherLabelChange}
                  placeholder="Enter label"
                />
              )}
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                disabled={submitting}
              />
              {errors.duplicate && (
                <span className="error">{errors.duplicate}</span>
              )}
            </div>
          </div>
          <hr />
          <h3>Selected Files</h3>
          <div className="upload-list">
            {fileList.length > 0 ? (
              fileList.map((doc, index) => (
                <div key={index} className="upload-item">
                  <span className="chip">{doc.label}</span>
                  <span className="file-name">{doc.file.name}</span>
                  {doc.uploaded ? (
                    <CheckCircle className="upload-tick" />
                  ) : (
                    <Delete
                      className="delete-icon"
                      onClick={() => handleRemoveFile(index)}
                    />
                  )}
                </div>
              ))
            ) : (
              <p>No files selected yet.</p>
            )}
          </div>
          <button onClick={handleSubmit} disabled={submitting}>
            {submitting ? "Uploading..." : "Upload Files"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddFiles;
