import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Charts.css";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import ProgressTrack from "../AragonCard/ProgressTrack/ProgressTrack";

import bootstrap from "../../../assets/bootstrap.jpg";
import angular from "../../../assets/angular.jpg";
import sketch from "../../../assets/sketch.jpg";
import react from "../../../assets/react.jpg";

const Charts = (props) => {
  const [dealPipelineData, setDealPipelineData] = useState({
    new: 0,
    reviewing: 0,
    dilligence: 0,
    deepDilligence: 0,
    offer: 0,
    total: 0,
  });

  const [leads, setLeads] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const [reviews, setReviews] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => { 
    var a = "63249a1a47c90756b4f4dda1";
    axios.get(
      `fetch_submitted_companies_for_opportunity?opportunity_id=${a}`
    ).then((res) => {
      let newCount = 0;
      let reviewingCount = 0;
      let dilligenceCount = 0;
      let deepDilligenceCount = 0;
      let offer = 0;
      let companies = res.data.full_company_list_with_flags;
      console.log(companies);
      companies.map((company) => {
        const createdOn = new Date(company.created_on);
        setLeads((prevLeads) => {
          const updatedLeads = [...prevLeads];
          updatedLeads[createdOn.getMonth()] += 1;
          return updatedLeads;
        });
        const companyReviews = company.reviews.length;
        setReviews((prevReviews) => {
          const updatedReviews = [...prevReviews];
          updatedReviews[createdOn.getMonth()] += companyReviews;
          return updatedReviews;
        });

        if (company.application_status === "new") {
          newCount++;
        }
        if (company.application_status === "review") {
          reviewingCount++;
        }
        if (company.application_status === "firstdiligence") {
          dilligenceCount++;
        }
        if (company.application_status === "deepdiligence") {
          deepDilligenceCount++;
        }
        if (company.application_status === "offer") {
          offer++;
        }
      });
      setDealPipelineData({
        new: newCount,
        reviewing: reviewingCount,
        dilligence: dilligenceCount,
        deepDilligence: deepDilligenceCount,
        offer: offer,
        total: companies.length,
      });
    });
  }, []);
  return (
    <div className="AragonCharts AragonFlex">
      <div className="AragonLineChartMain">
        <div className="AragonLineChart">
          <div className="AragonFlex AragonLineChartOverView">
            <div>
              <h6>OVERVIEW</h6>
              <h3>Leads Per Month</h3>
            </div>
          </div>
          <div style={{ height: "360px" }} className="lineabc">
            <LineChart data={leads} />
          </div>
        </div>
      </div>
      <div className="AragonBarChartMain">
        <div className="AragonBarChart">
          <div>
            <h6>PERFORMANCE</h6>
            <h3>Reviews per month</h3>
          </div>
          <div style={{ height: "360px" }}>
            <BarChart data={reviews} />
          </div>
        </div>
      </div>
      <div className="AragonWorkCards AragonM-l">
        <div className="AragonWorkCardsTitle">Deal Pipeline</div>
        <ProgressTrack
          avtar={bootstrap}
          title={`New Leads (${dealPipelineData.new})`}
          color="#FB6340"
          width={(dealPipelineData.new / dealPipelineData.total) * 100 + "%"}
        />
        <ProgressTrack
          avtar={angular}
          title={`Reviewing (${dealPipelineData.reviewing})`}
          color="#2DCE89"
          width={
            (dealPipelineData.reviewing / dealPipelineData.total) * 100 + "%"
          }
        />
        <ProgressTrack
          avtar={sketch}
          title={`1st Dilligence (${dealPipelineData.dilligence})`}
          color="#F5365C"
          width={
            (dealPipelineData.dilligence / dealPipelineData.total) * 100 + "%"
          }
        />
        <ProgressTrack
          avtar={react}
          title={`Deep Dilligence (${dealPipelineData.deepDilligence})`}
          color="#11CDEF"
          width={
            (dealPipelineData.deepDilligence / dealPipelineData.total) * 100 +
            "%"
          }
        />
        <ProgressTrack
          avtar={bootstrap}
          title={`Offer (${dealPipelineData.offer})`}
          color="#FB6340"
          width={(dealPipelineData.offer / dealPipelineData.total) * 100 + "%"}
        />
      </div>
    </div>
  );
};

export default Charts;
