import React, { useState } from "react";
import Submenu from "../submenu";
import StartupScore from "./startupScore";
import Tearsheet from "./tearsheet";
import SubReports from "./subReports";
import Summary from "./summary";

const ReportsMain = ({
  companyInfo,
  applicationObject,
  opportunityInfo,
  handleCreditChange,
  updateCompanyData,
}) => {
  const menuItems = ["Tear sheet", "Summary", "StartupScore"];
  const [activeItem, setActiveItem] = useState("StartupScore");

  const renderActiveComponent = () => {
    switch (activeItem) {
      case "Tear sheet":
        return (
          <Tearsheet
            updateCompanyData={updateCompanyData}
            applicationInfo={applicationObject}
            company={companyInfo}
            handleCreditChange={handleCreditChange}
            opportunity_data={opportunityInfo}
          />
        );
      case "Summary":
        return (
          <Summary
            updateCompanyData={updateCompanyData}
            applicationObject={applicationObject}
            companyInfo={companyInfo}
            handleCreditChange={handleCreditChange}
          />
        );
      case "StartupScore":
        return (
          <StartupScore
            updateCompanyData={updateCompanyData}
            companyInfo={companyInfo}
            applicationObject={applicationObject}
            opportunityInfo={opportunityInfo}
            handleCreditChange={handleCreditChange}
          />
        );
      // case "Idea":
      //   return (
      //     <SubReports
      //       updateCompanyData={updateCompanyData}
      //       page="Idea"
      //       companyInfo={companyInfo}
      //       applicationObject={applicationObject}
      //       opportunityInfo={opportunityInfo}
      //       handleCreditChange={handleCreditChange}
      //     />
      //   );
      // case "Product":
      //   return (
      //     <SubReports
      //       updateCompanyData={updateCompanyData}
      //       page="Tech"
      //       companyInfo={companyInfo}
      //       applicationObject={applicationObject}
      //       opportunityInfo={opportunityInfo}
      //       handleCreditChange={handleCreditChange}
      //     />
      //   );
      // case "Team":
      //   return (
      //     <SubReports
      //       updateCompanyData={updateCompanyData}
      //       page="Team"
      //       companyInfo={companyInfo}
      //       applicationObject={applicationObject}
      //       opportunityInfo={opportunityInfo}
      //       handleCreditChange={handleCreditChange}
      //     />
      //   );
      // case "Marketing":
      //   return (
      //     <SubReports
      //       updateCompanyData={updateCompanyData}
      //       page="Marketing"
      //       companyInfo={companyInfo}
      //       applicationObject={applicationObject}
      //       opportunityInfo={opportunityInfo}
      //       handleCreditChange={handleCreditChange}
      //     />
      //   );
      // case "Traction":
      //   return (
      //     <SubReports
      //       updateCompanyData={updateCompanyData}
      //       page="Traction"
      //       companyInfo={companyInfo}
      //       applicationObject={applicationObject}
      //       opportunityInfo={opportunityInfo}
      //       handleCreditChange={handleCreditChange}
      //     />
      //   );
      default:
        return null;
    }
  };

  return (
    <div>
      <Submenu
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        menuItems={menuItems}
      />
      <div className="reports-content">{renderActiveComponent()}</div>
    </div>
  );
};

export default ReportsMain;
