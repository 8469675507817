import React, { Component } from "react";
import axios from "axios";
import URL from "url-parse";
import StarRatings from "react-star-ratings";
import { ProgressBar, Row, Col } from "react-bootstrap";
import Comment from "./../comment/Comment";
import Profile_Card from "../../../components/application_room_card_flagged";
import default_pic from "../../../../img/default_company_pic.png";
import default_background from "../../../../img/bg.png";
import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../../../boilerplate/list.json";

import { Modal } from "react-bootstrap";
import ReviewsCards from "./../ReviewsCard";

import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import "./flag.scss";
import { Report_Company_Flagging } from "../../../components/popups";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      companies: [],
      companies_with_flag: [],
      static_companies_with_flag: [],
      selected_company_id: "",
      selected_company: "",
      showOpenReview: false,
      showPopUpRate: false,
      showSideRating: false,
      filter_number: "",

      problem: 5,
      solution: 5,
      traction: 5,
      idea: 5,
      technology: 5,
      marketing: 5,
      team: 5,
      overallRating: 5,
      comment: "",
      feedback: "",
      reviews: [],
      current_judge_has_reviewed: false,
      current_judge_review: {},
      totalOverall: 0,
      totalIdea: 0,
      totalTechnology: 0,
      totalMarketing: 0,
      totalTeam: 0,

      showFlagPopup: false,
      popup_header: " ",
      popup_body: " ",
      applicant_rejection_email: {
        header: "Thank you for applying!",
        body: `We are sorry to inform you that we are not able to move forward with your application at this time.
              `,
        email: "",
      },
      activeCompany: {},
    };
  }

  componentDidMount() {
    axios
      .get(
        `fetch_flagged_companies_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        if (
          response.data.status === "success" &&
          response.data.full_company_list_with_flags.length > 0
        ) {
          this.setState({
            companies: response.data.full_company_list_with_flags.filter(
              (x) => x.flagged !== undefined,
            ),
          });
        }
      });
  }

  updateApplicationObject = (company) => {
    const applicationObject = {};

    Object.keys(this.props.opportunity_data.opportunity_form).forEach(
      (opp_form_key) => {
        if (
          this.props.opportunity_data.opportunity_form[opp_form_key][
            "display"
          ] === true &&
          company[opp_form_key] !== undefined &&
          company[opp_form_key] !== ""
        ) {
          applicationObject[opp_form_key] = {
            Title:
              this.props.opportunity_data.opportunity_form[opp_form_key][
                "title"
              ],
            Answer: company[opp_form_key],
          };
        }
      },
    );

    return applicationObject;
  };

  getAvatarUrl = (array, file_type, temp_background_url) => {
    let new_arr = array.filter((file) => {
      return file.file_type === file_type;
    });
    if (file_type === "company avatar") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    }
    if (file_type === "company background") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        if (
          temp_background_url !== undefined &&
          temp_background_url !== null &&
          temp_background_url.length > 0
        ) {
          return temp_background_url;
        } else {
          return default_background;
        }
      }
    }
  };

  getFileUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      }
    } else {
      return 0;
    }
  };
  getBackgroundUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_background;
      }
    } else {
      return default_background;
    }
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };
  changeRating = (newRating, name) => {
    this.setState(
      {
        [name]: newRating,
      },
      () => {
        this.setState({
          overallRating:
            (this.state.idea +
              this.state.technology +
              this.state.marketing +
              this.state.team) /
            4,
        });
      },
    );
  };
  handleToggle = (e) => {
    e.preventDefault();
    this.setState({
      showOpenReview: !this.state.showOpenReview,
    });
  };
  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleDelete = (e, company_id, isStealth = false) => {
    const { opportunity_id } = this.state.url.query;

    this.setState({
      applicant_rejection_email: {
        ...this.state.applicant_rejection_email,
        email: this.state.activeCompany?.prospective_aff_email,
      },
    });

    axios
      .post("/delete_flagged_company_from_opportunity", {
        opportunity_id: opportunity_id,
        company_id: company_id,
        applicant_rejection_email: this.state.applicant_rejection_email,
        isStealth: isStealth,
      })
      .then(() => {
        return axios.get(
          `fetch_flagged_companies_for_opportunity?opportunity_id=${opportunity_id}`,
        );
      })
      .then((response) => {
        const { full_company_list_with_flags } = response.data;
        if (
          full_company_list_with_flags &&
          full_company_list_with_flags.length > 0
        ) {
          this.setState({
            companies: full_company_list_with_flags.filter(
              (x) => x.flagged !== undefined,
            ),
          });
        } else {
          this.setState({ companies: [] });
        }
        this.setState({
          showFlagPopup: false,
        });
      })
      .catch((error) => {
        console.error("Error updating companies:", error);
      });
  };
  handleRestore = (e, company_id) => {
    axios
      .post(
        `/remove_flag_from_company_in_opportunity?opportunity_id=${this.state.url.query.opportunity_id}&&company_id=${company_id}`,
      )
      .then((response) => {
        axios
          .get(
            `fetch_flagged_companies_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
          )
          .then((response) => {
            this.setState({
              companies: response.data.full_company_list_with_flags.filter(
                (x) => x.flagged !== undefined,
              ),
            });
          });
      });
  };

  handleFlaggingChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleFlaggingHide = (e) => {
    this.setState({
      showFlagPopup: false,
    });
  };

  handleRejectionEmail = (e) => {
    const name = e.target.name;
    this.setState({
      applicant_rejection_email: {
        ...this.state.applicant_rejection_email,
        [name]: e.target.value,
      },
    });
  };

  showDeletePopup = (e, company) => {
    e.preventDefault();
    this.setState({
      activeCompany: company,
      popup_header: `Are you sure you would like to delete ${company.company_name}?`,
      popup_body: "Deleted companies will NOT be recoverable.",
      showFlagPopup: true,
    });
  };

  renderFlaggedOpportunities() {
    return (
      <div className="__application-flag-room">
        <Report_Company_Flagging
          show={this.state.showFlagPopup}
          handleChange={this.handleFlaggingChange}
          dismiss={this.handleFlaggingHide}
          company_id={this.state.activeCompany._id}
          handleDelete={this.handleDelete}
          handleRejectionEmail={this.handleRejectionEmail}
          headerRejection={this.state.applicant_rejection_email.header}
          bodyRejection={this.state.applicant_rejection_email.body}
          isArchived={true}
        />
        <div className="__application-room-padding __header">
          <h3>
            Flagged Submissions <i className="material-icons">flag</i>
          </h3>
        </div>
        <div className="__application-room-profile-card-grid">
          {this.state.companies.map((company, index) => (
            <Profile_Card
              key={index}
              getStage={this.getStage}
              updateApplicationObject={this.updateApplicationObject}
              previewSelectedCompany={this.previewSelectedCompany}
              getAvatarUrl={this.getAvatarUrl}
              handleDelete={this.handleDelete}
              getFileUrl={this.getFileUrl}
              handleRestore={this.handleRestore}
              company_owner={company.owner}
              showDeletePopup={this.showDeletePopup}
              isArchived={true}
              {...company}
              index={index}
            />
          ))}
        </div>
      </div>
    );
  }

  renderNoFlaggedOpportunities() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <p>No flagged deals</p>
        <p>
          View all deals{" "}
          <a
            href={`applicant_room?opportunity_id=${this.state.url.query.opportunity_id}`}
          >
            here
          </a>
        </p>
      </div>
    );
  }

  render() {
    return this.state.companies.length > 0
      ? this.renderFlaggedOpportunities()
      : this.renderNoFlaggedOpportunities();
  }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Page;
