/* (2019-06-11) - This is a file contains all of the lists used throughout the
site. If you want to change a list on the site, just change it here... usually.
*/
const base_profile_fields = {
  company_name: {
    header: "Company Name",
    sub_text: "Enter your company name.",
    type: "text",
    percent: 5,
  },
  company_type: {
    header: "Company Type",
    sub_text: "Select the type of company you operate.",
    type: "dropdown",
    percent: 5,
    list: "company_type_list",
  },
  short_bio: {
    header: "Elevator Pitch",
    sub_text: "Enter a brief summary on your company.",
    type: "textarea",
    percent: 5,
  },
  detailed_description: {
    header: "Detailed Description",
    sub_text: "Enter a detailed description of what your company does.",
    type: "textarea",
    percent: 5,
  },
  company_website: {
    header: "Company Website",
    sub_text: "Enter your company website.",
    type: "text",
    special: "website",
    percent: 5,
  },
  industry: {
    header: "Industries of Operation",
    sub_text: "Please select the industries your company operates in.",
    type: "dropdown",
    percent: 5,
    list: "industry_list",
  },
  stage: {
    header: "Company Stage",
    sub_text: "What stage is your company at?",
    type: "dropdown",
    percent: 5,
    list: "industry_list",
  },
  business_model: {
    header: "Business Model",
    sub_text: "What stage is your company at?",
    type: "dropdown",
    percent: 5,
    list: "industry_list",
  },
  company_avatar: {
    header: "Company Logo",
    sub_text: "Upload your company logo.",
    type: "file",
    file_type: "company avatar",
    percent: 10,
    dataFileType: "avatar",
    name: "user avatar",
  },
  company_background: {
    header: "Background Image",
    sub_text: "Enter a background image for your profile.",
    type: "file",
    name: "avatar",
    file_type: "company background",
    percent: 10,
    dataFileType: "background",
  },
  facebook: {
    header: "Company Facebook",
    sub_text: "Enter a link to your company's facebook URL.",
    type: "text",
    special: "website",
    percent: 5,
  },
  twitter: {
    header: "Company Twitter",
    sub_text: "Enter a link to your company's twitter URL.",
    type: "text",
    special: "website",
    percent: 2.5,
  },
  linkedIn: {
    header: "Company LinkedIn",
    sub_text: "Enter a link to your company's LinkedIn URL.",
    type: "text",
    special: "website",
    percent: 5,
  },
  instagram: {
    header: "Company Instagram",
    sub_text: "Enter a link to your company's Instagram URL.",
    type: "text",
    special: "website",
    percent: 2.5,
  },
  iOS_app_url: {
    header: "iOS App",
    sub_text: "Enter a link so people can download your iOS app.",
    type: "text",
    special: "website",
    percent: 2.5,
  },
  android_app_url: {
    header: "Android App",
    sub_text: "Enter a link so people can download your Android app.",
    type: "text",
    special: "website",
    percent: 2.5,
  },
  looking_for: {
    header: "Current Goal",
    sub_text:
      "What is your company looking for the most right now out of the following options.",
    type: "dropdown",
    percent: 5,
    list: "goals",
    user_type: "startup",
  },
  looking_for: {
    header: "Current Goal",
    sub_text:
      "What is your company looking for the most right now out of the following options.",
    type: "dropdown",
    percent: 5,
    list: "investor_goals",
    user_type: "investor",
  },

  contact_email: {
    header: "Company Email",
    sub_text: "Enter an email that people can contact.",
    type: "text",
    special: "email",
    percent: 5,
  },
  contact_phone: {
    header: "Company Phone #",
    sub_text: "Enter a number that people can call to reach you.",
    type: "text",
    percent: 2.5,
  },
  address: {
    header: "Company Address",
    sub_text: "Enter your company's location.",
    type: "text",
    percent: 2.5,
  },
  date_of_inception: {
    header: "Company Incorporation",
    sub_text: "Enter the date your company was incorporated.",
    type: "date",
    percent: 5,
  },
};
const pitch_fields = [
  "pitch_video_link",
  // objective
  // profitable
  // problem
  // target_market
  // market_size
  // solution
  // competitors
  // differentiation
  // revenue_model
  // revenue_since_inception
  // growth
  // traction
  // marketing_strategy
  // team_description
  // attempting_to_raise
  // last_raise
  // evaluation
  // raising_funds
  // incubator_accelerator_history
  // fintech_sub_vertical
];
const individual_user_type_list = [
  "General User",
  "Referral Partner",
  "Community Manager",
  "Admin",
];

const country_list = [
  "Canada",
  "United States",
  "UK",
  "Argentina",
  "Austria",
  "Australia",
  "Bangladesh",
  "Brazil",
  "Belgium",
  "Cameroon",
  "Chile",
  "China",
  "Croatia",
  "Colombia",
  "Denmark",
  "Egypt",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Ghana",
  "Greece",
  "Hong Kong",
  "India",
  "Indonesia",
  "Iran",
  "Ireland",
  "Israel",
  "Italy",
  "Japan",
  "Kenya",
  "Lithuania",
  "Malaysia",
  "Mexico",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nigeria",
  "Norway",
  "Pakistan",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "Saudi Arabia",
  "Singapore",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Thailand",
  "Turkey",
  "UAE",
  "Uganda",
  "Ukraine",
  "Uruguay",
  "Vietnam",
];
const business_type = ["B2B", "B2C", "B2G", "C2C"];
const goals = [
  "Establish a Company",
  "Build a Team",
  "Develop Product",
  "Market Product",
  "Raise Funds",
];
const investor_goals = [
  "Search Startups",
  "Raise Funds",
  "Build A Team",
];

const years = [
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
];

const debt_type_list = ["Loan", "Convertible Note", "SAFE"];

const company_type_list = [
  "Startup",
  "Tech Startup",
  "Corporate",
  "Educator",
  "Event",
  "Investor",
  "Service Provider",
  "Workspace",
  "Accelerator",
  "Incubator",
];
const add_company_type_list = ["Startup", "Tech Startup"];
const type_of_service_provider = [
  "Tech",
  "Marketing",
  "Legal",
  "Fundraising",
  "Accounting",
  "Operations",
];
const product_categories = [
  "Lead Generation",
  "Marketing",
  "Legal",
  "Accounting",
  "Operations",
  "Networking",
  "Social",
  "Tech Development",
  "Graphic Design",
  "Communication",
  "HR",
  "CRM",
  "Analytics",
  "Customer Support",
  "Security",
  "Productivity",
];

const opportunity_type_list = [
  "Pitch Competitions",
  "Accelerator",
  "Incubator",
  "Innovation Challenges",
  "Debt Financing",
  "Equity Financing",
  /*'Events',
  'Hackathons',
  'Grants',*/
];

const vertical_list = [
  "Payments",
  "Personal Finance",
  "Cryptocurrencies",
  "Blockchain",
  "Cyber Security",
  "Capital Markets",
  "Banking",
  "Lending",
  "Artificial Intelligence",
  "Accounting",
  "RegTech",
  "InsurTech",
  "Real Estate",
];

const opportunity_fields_values = {
  start_date: {
    opportunity_type: ["Events", "Pitch Competitions", "Hackathons"],
    //registration
    label: "Start Date",
    type: "datetime-local",
    value: "start_date",
    placeholder: "Start Date",
    onChange: "handleChange",
  },
  end_date: {
    opportunity_type: ["Events", "Pitch Competitions", "Hackathons"],
    //registration
    label: "End Date",
    type: "datetime-local",
    value: "end_date",
    placeholder: "End Date",
    onChange: "handleChange",
  },
  ticket_price_check: {
    opportunity_type: ["Events"],
    type: "ticket_price_checked",
  },
  ticket_price: {
    opportunity_type: ["Events"],
    //registration
    label: "Ticket Price",
    type: "number",
    value: "ticket_price",
    placeholder: "Ticket Price",
    onChange: "handleChange",
    check_dependent: "yes",
  },
  venue_location: {
    opportunity_type: ["Events"],
    //registration
    label: "Venue Location",
    type: "text",
    value: "venue_location",
    placeholder: "Venue Location",
    onChange: "handleChange",
  },
  deadline: {
    opportunity_type: [
      "Grants",
      "Hackathons",
      "Pitch Competitions",
      "Innovation Challenges",
      "Events",
    ],
    //registration
    label: "Deadline",
    type: "datetime-local",
    value: "deadline",
    placeholder: "Deadline",
    onChange: "handleChange",
  },
  // deadline_to_complete:{
  //   opportunity_type:['Innovation Challenges'],
  //   //registration
  //   label: 'Deadline To Complete',
  //   type:'dateime-local',
  //   value:'deadline_to_complete',
  //   placeholder:'Deadline To Complete',
  //   onChange:'handleChange'
  // },
  amount: {
    opportunity_type: [
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Hackathons",
    ],
    //registration
    label: "Amount",
    type: "number",
    value: "amount",
    placeholder: "Amount",
    onChange: "handleChange",
  },
  prize_pitch: {
    opportunity_type: ["Pitch Competitions"],
    //registration
    label: "Pitch Prize",
    type: "number",
    value: "prize_pitch",
    placeholder: "Pitch Prize",
    onChange: "handleChange",
  },
  prize_challenge: {
    opportunity_type: ["Innovation Challenges"],
    //registration
    label: "Challenge Prize",
    type: "number",
    value: "prize_challenge",
    placeholder: "Challenge Prize",
    onChange: "handleChange",
  },
  debt_type: {
    opportunity_type: ["Debt Financing"],
    type: "dropdown",
    label: "Debt Type",
    componentclass: "select",
    placeholder: "select",
    name: "debt_type",
    onChange: "handleChange",
    mapping: debt_type_list,
  },
  objective: {
    opportunity_type: ["Innovation Challenges", "Hackathons"],
    type: "textarea",
    componentclass: "textarea",
    label: "Objective",
    value: "objective",
    placeholder: "Please provide a description of the objective",
    name: "objective",
    onChange: "handleChange",
  },
};

const industry_list = [
  "Accounting",
  "Agriculture",
  "AI/ML",
  "Blockchain",
  "Cannabis",
  "Construction",
  "COVID-19",
  "Data",
  "E-Commerce",
  "Education",
  "Employment",
  "Energy",
  "Enterprise Software",
  "Environmental",
  "Fashion",
  "Fintech",
  "Fitness",
  "Food",
  "Gaming",
  "Healthcare",
  "Hospitality",
  "IoT",
  "Legal",
  "Lifestyle",
  "Manufacturing",
  "Marketing",
  "Media",
  "Photography",
  "PropTech",
  "Real Estate",
  "Retail",
  "Robotics",
  "Security",
  "Social Media",
  "Space",
  "Sports",
  "Telecom",
  "Transportation",
  "Travel",
];

const stage_list = [
  {
    value: 1.1,
    text: "Pre-Seed",
  },
  {
    value: 1.2,
    text: "Seed",
  },
  {
    value: 2.1,
    text: "Series A",
  },
  {
    value: 2.2,
    text: "Series B",
  },
  {
    value: 2.3,
    text: "Series C",
  },
  {
    value: 3,
    text: "IPO",
  },
];

const distinction_list = [
  {
    value: "female_founder",
    text: "Female Founders",
  },
  {
    value: "aboriginal",
    text: "Aboriginal Founders",
  },
  {
    value: "minority",
    text: "Visible Minority Founder",
  },
  {
    value: "lgbtq",
    text: "LGBTQ+ Founders",
  },
  {
    value: "veteran",
    text: "Veteran Founders",
  },
  {
    value: "disabled",
    text: "Founders of Disability",
  },
];

// module.exports = {
//   company_type_list,
//   add_company_type_list,
//   industry_list,
//   stage_list,
//   business_type,
//   opportunity_type_list,
//   country_list,
//   opportunity_fields_values,
//   years,
//   goals,
//   investor_goals,
//   individual_user_type_list,
//   vertical_list,
//   debt_type_list,
//   base_profile_fields,
//   type_of_service_provider,
//   product_categories,
//   distinction_list,
// };
