import React, { useState, useEffect } from "react";
import axios from "axios";

const BasicInfoStep = ({
  formData,
  setFormData,
  nextStep,
  isLoggedIn,
  setCompanyInfo,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [showLogin, setShowLogin] = useState(false);
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [loginErrors, setLoginErrors] = useState({});
  const [apiError, setApiError] = useState("");

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    if (isLoggedIn) {
      nextStep();
    }
  }, [isLoggedIn, nextStep]);

  useEffect(() => {
    if (formData.foundedMonth) {
      setSelectedMonth(formData.foundedMonth);
    }
    if (formData.foundedYear) {
      setSelectedYear(formData.foundedYear);
    }
  }, [formData]);

  const handleNextButtonClick = async () => {
    if (Object.values(errors).some((error) => error) || !isFormValid()) {
      return;
    }
    await createUser();
  };

  const isFormValid = () => {
    let valid = true;
    let errorsTemp = {};

    if (!formData.firstName) {
      errorsTemp.firstName = "First Name is required";
      valid = false;
    }

    if (!formData.lastName) {
      errorsTemp.lastName = "Last Name is required";
      valid = false;
    }

    if (!formData.email) {
      errorsTemp.email = "Email is required";
      valid = false;
    } else {
      const emailValid = formData.email.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
      );
      if (!emailValid) {
        errorsTemp.email = "Email is invalid";
        valid = false;
      }
    }

    if (!formData.password) {
      errorsTemp.password = "Password is required";
      valid = false;
    } else if (formData.password.length < 6) {
      errorsTemp.password = "Password must be at least 6 characters";
      valid = false;
    }

    if (!formData.companyName) {
      errorsTemp.companyName = "Company Name is required";
      valid = false;
    }

    setErrors(errorsTemp);
    return valid;
  };

  const fieldLabels = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    password: "Password",
    companyName: "Company Name",
    foundedMonth: "Founded Month",
    foundedYear: "Founded Year",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData({ ...formData, [name]: trimmedValue });
    handleUserInput(name, trimmedValue);
  };

  const handleUserInput = (name, value) => {
    let errorsTemp = { ...errors };
    const fieldLabel = fieldLabels[name] || name;

    if (!value) {
      errorsTemp[name] = `${fieldLabel} is required`;
    } else {
      if (name === "email") {
        const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        errorsTemp.email = emailValid ? "" : "Email is invalid";
      } else if (name === "password") {
        errorsTemp.password =
          value.length < 6 ? "Password must be at least 6 characters" : "";
      } else if (name === "companyName") {
        errorsTemp.companyName =
          value.length < 2 ? "Company Name must be at least 2 characters" : "";
      } else {
        errorsTemp[name] = ""; // Clear the error message for this field if valid
      }
    }

    setErrors(errorsTemp);
  };

  const createUser = async () => {
    setIsSubmitting(true);
    setApiError(""); // Clear previous API errors
    try {
      // Create user
      const userResult = await axios.post("/create_user", {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        password: formData.password,
      });

      if (userResult.data.status === "existing user") {
        setErrors({ email: "User already exists" });
        return;
      }

      const userId = userResult.data.data._id;

      // Create company
      const companyResponse = await axios.post("/create_company", {
        user_id: userId,
        company_name: String(formData.companyName) || "",
        company_type: "startup", // Always set company type to "startup"
        founded_month: selectedMonth,
        founded_year: selectedYear,
      });

      setCompanyInfo(companyResponse.data.result);

      nextStep();
    } catch (error) {
      console.error("Error creating user or company:", error);
      setApiError(
        error.response?.data?.message ||
          "An error occurred while creating the user or company.",
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Login code
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setLoginData({ ...loginData, [name]: trimmedValue });
    handleLoginInput(name, trimmedValue);
  };

  const handleLoginInput = (name, value) => {
    let errorsTemp = { ...loginErrors };
    const fieldLabel = fieldLabels[name] || name;

    if (!value) {
      errorsTemp[name] = `${fieldLabel} is required`;
    } else {
      if (name === "email") {
        const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        errorsTemp.email = emailValid ? "" : "Email is invalid";
      } else if (name === "password") {
        errorsTemp.password =
          value.length < 6 ? "Password must be at least 6 characters" : "";
      } else {
        errorsTemp[name] = ""; // Clear the error message for this field if valid
      }
    }

    setLoginErrors(errorsTemp);
  };

  const handleLogin = async () => {
    if (
      Object.values(loginErrors).some((error) => error) ||
      !loginData.email ||
      !loginData.password
    ) {
      return;
    }
    setIsSubmitting(true);
    setApiError(""); // Clear previous API errors
    try {
      const loginResult = await axios.post("/login", {
        username: loginData.email,
        password: loginData.password,
      });

      if (loginResult.data.status === "success") {
        // Call the /companies_user_owns endpoint after successful login
        const companiesResult = await axios.get("/companies_user_owns");

        if (companiesResult.data.status === "No Companies") {
          setCompanyInfo({});
        } else {
          setCompanyInfo(companiesResult.data.result[1][0]);
          console.log("Companies data:", companiesResult.data.result[1][0]);
          nextStep(companiesResult.data); // Pass the data to the next step if needed
        }
      } else {
        setApiError(loginResult.data.message || "Login failed");
      }
    } catch (error) {
      console.error("Error logging in or fetching companies:", error);
      setApiError(
        error.response?.data?.message || "An error occurred during login.",
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const monthOptions = Array.from({ length: 12 }, (_, i) => i + 1);
  const yearOptions = Array.from(
    { length: 100 },
    (_, i) => currentYear - i,
  ).reverse();

  return isLoggedIn ? null : (
    <div className="opportunity-submission-card">
      <h1>Basic Info</h1>
      <h3>Provide Info about yourself before we proceed.</h3>
      {apiError && <p className="error">{apiError}</p>}
      {!showLogin ? (
        <div>
          <div className="responsive-grid">
            <div className="form-group">
              <label>First Name:</label>
              <input
                type="text"
                name="firstName"
                placeholder="Enter your First name"
                value={formData.firstName}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
              {errors.firstName && (
                <span className="error">{errors.firstName}</span>
              )}
            </div>
            <div className="form-group">
              <label>Last Name:</label>
              <input
                type="text"
                name="lastName"
                placeholder="Enter your Last name"
                value={formData.lastName}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
              {errors.lastName && (
                <span className="error">{errors.lastName}</span>
              )}
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
              {errors.password && (
                <span className="error">{errors.password}</span>
              )}
            </div>
            <div className="form-group">
              <label>Company Name:</label>
              <input
                type="companyName"
                name="companyName"
                placeholder="Enter the name of your company"
                value={formData.companyName}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
              {errors.companyName && (
                <span className="error">{errors.companyName}</span>
              )}
            </div>
            <div className="form-group">
              <label>Founded Date:</label>
              <div className="dropdown-group">
                <select
                  name="foundedMonth"
                  value={selectedMonth}
                  onChange={(e) => {
                    setSelectedMonth(e.target.value);
                    setFormData({
                      ...formData,
                      foundedMonth: e.target.value,
                    });
                  }}
                >
                  {monthOptions.map((month) => (
                    <option key={month} value={month}>
                      {month.toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
                <select
                  name="foundedYear"
                  value={selectedYear}
                  onChange={(e) => {
                    setSelectedYear(e.target.value);
                    setFormData({
                      ...formData,
                      foundedYear: e.target.value,
                    });
                  }}
                >
                  {yearOptions.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <p
            onClick={() => setShowLogin(true)}
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
          >
            Already have an account? Login
          </p>
          <button onClick={handleNextButtonClick} disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Next"}
          </button>
        </div>
      ) : (
        <div>
          <div className="responsive-grid">
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={loginData.email}
                onChange={handleLoginChange}
                style={{ width: "100%" }}
              />
              {loginErrors.email && (
                <span className="error">{loginErrors.email}</span>
              )}
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                value={loginData.password}
                onChange={handleLoginChange}
                style={{ width: "100%" }}
              />
              {loginErrors.password && (
                <span className="error">{loginErrors.password}</span>
              )}
            </div>
          </div>
          <p
            onClick={() => setShowLogin(false)}
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
          >
            Don't have an account? Sign up
          </p>
          <button onClick={handleLogin} disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Login"}
          </button>
        </div>
      )}
    </div>
  );
};

export default BasicInfoStep;
