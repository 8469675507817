import React from 'react';
import './_M-backdrop.scss';


const backdrop = props => (
  <div
    className="backdrop"
    onClick={ props.click } />

)



export default backdrop;