/* (2019-06-11) - This is the left side when creating/editing an opportunity.
Contains information on the company (profile picture, name, etc)
*/
import React from "react";
import axios from "axios";
import update from "immutability-helper";
import { SocialIcon } from "react-social-icons";
import {
  Navbar,
  Nav,
  Row,
  Col,
  Card,
  Button,
  ButtonToolbar,
  Container,
  FormLabel,
  Form,
} from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import "../../components/profile.css";
import "./opportunities.css";
import DisplayToggle from "../DisplayToggle";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Checkbox from "@material-ui/core/Checkbox";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import { Helmet } from "react-helmet";
import URL from "url-parse";
import default_pic from "../../../img/default_company_pic.png";
import default_background from "../../../img/bg.png";
import rocket from "../../../img/rocketlaunch.jpg";
import StartupFuel from "../../../img/watermarksffinal.png";
import Loader from "../../components/loader";
import {
  Profile_Photo_Update,
  Cover_Photo_Update,
  Company_Update,
  Sign_In,
  Register,
} from "../../components/popups";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
  company_type_list,
  goals,
} from "../../../boilerplate/list.json";
import SiteTitle from "../../components/site_title.js";

import waterfall from "async/waterfall";

import { AppContext } from "../../../App";
import Navigation from "../../components/navbar";
import Footer from "./../../components/footer";
import Moment from "moment";

import CompanyNavBar from "./nav_bar";
import "./ubaidOppCustom.css";

import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class CustomizedTable extends React.Component {
  constructor(props) {
    super(props);
  }
  handleAllowInvestor = (e) => {
    this.props.handleInvestorChange(e);
    this.props.handleAlertPopup();
  };
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.props.userIsProfileOwner && (
          <div>
            <div style={{ width: "100%" }}>
              <CompanyNavBar
                menuSelection={this.props.menuSelection}
                handleMenuChange={this.props.handleMenuChange}
                company_type={this.props.company_type}
                allow_investor={this.props.allow_investor}
                userIsProfileOwner={this.props.userIsProfileOwner}
              />
              <DisplayToggle
                show={
                  this.props.opportunities_owned !== undefined &&
                  this.props.opportunities_owned !== null &&
                  this.props.opportunities_owned.length !== 0
                }
              >
                <div>
                  {this.props.opportunities_owned &&
                    this.props.opportunities_owned.map((opp) => (
                      <>
                        {opp.trial_start_date === undefined ||
                        opp.is_paying ||
                        new Date(
                          Moment(opp.trial_start_date).add(
                            opp.trial_period_length,
                            "days"
                          )
                        ) > Date.now() ? (
                          <div class="card opp-card-horz">
                            <div className="ubaidOppCompImage">
                              <div>
                                <img
                                  class="opp-card-image"
                                  src={
                                    opp.background_cover !== null
                                      ? opp.background_cover
                                      : default_background
                                  }
                                />
                              </div>
                            </div>
                            <div class="opp-card-content ubaidContentOpp">
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <h4>
                                  <a
                                    href={
                                      "/opportunity?opportunity_id=" + opp._id
                                    }
                                  >
                                    {opp.opportunity_name}
                                  </a>
                                </h4>{" "}
                                {opp.trial_start_date !== undefined &&
                                  !opp.is_paying &&
                                  new Date(
                                    Moment(opp.trial_start_date).add(
                                      opp.trial_period_length,
                                      "days"
                                    )
                                  ) > Date.now() && ( 
                                    <br />
                                    // <p
                                    //   style={{ float: "right", color: "green" }}
                                    // >
                                    //   Free Trial:
                                    //   {Moment.duration(
                                    //     Moment(opp.trial_start_date)
                                    //       .add(opp.trial_period_length, "days")
                                    //       .diff(Moment(Date.now()))
                                    //   )
                                    //     .asDays()
                                    //     .toFixed(0)}{" "}
                                    //   days remaining
                                    // </p>
                                  )}
                              </span>
                              {opp.venue_location !== undefined &&
                                opp.venue_location.length > 0 && (
                                  <p>
                                    <i
                                      class="fa fa-map-marker"
                                      aria-hidden="true"
                                    ></i>
                                    {opp.venue_location}
                                  </p>
                                )}
                              <p>{opp.opportunity_description}</p>
                              <div class="card__bottom">
                                <div class="options">
                                  {opp.deadline !== null && (
                                    <span class="date">
                                      <i
                                        class="fa fa-calendar"
                                        aria-hidden="true"
                                      ></i>
                                      Deadline:{" "}
                                      {Moment(opp.deadline).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                      )}
                                    </span>
                                  )}
                                  {opp.start_intake_date !== null && (
                                    <span class="date">
                                      <i
                                        class="fa fa-calendar"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Start Date:{" "}
                                      {Moment(opp.start_intake_date).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                      )}
                                    </span>
                                  )}
                                  {opp.end_intake_date !== null && (
                                    <span class="date">
                                      <i
                                        class="fa fa-calendar"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      End Date:{" "}
                                      {Moment(opp.end_intake_date).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                      )}
                                    </span>
                                  )}
                                </div>
                                <div class="card__price">{opp.price}</div>
                              </div>
                              <div className="__footer">
                                {opp.has_applicant_room !== undefined &&
                                  opp.has_applicant_room !== null &&
                                  opp.has_applicant_room !== false && (
                                    <a
                                      href={`/applicant_room?opportunity_id=${opp._id}`}
                                    >
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-white"
                                      >
                                        {" "}
                                        Applicant Room{" "}
                                      </button>
                                    </a>
                                  )}
                                <a
                                  href={`/edit_opportunity?opportunity_id=${opp._id}`}
                                >
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-white"
                                  >
                                    {" "}
                                    Edit{" "}
                                  </button>
                                </a>
                                <button
                                  onClick={(event) => {
                                    this.props.deleteOpportunity(
                                      event,
                                      opp._id
                                    );
                                  }}
                                  type="button"
                                  class="btn btn-sm btn-danger"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div class="card opp-card-horz">
                            <div className="ubaidOppCompImage">
                              <div>
                                <img
                                  class="opp-card-image"
                                  src={
                                    opp.background_cover !== null
                                      ? opp.background_cover
                                      : default_background
                                  }
                                />
                              </div>
                            </div>
                            <div class="opp-card-content ubaidContentOpp">
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <h4>
                                  <a
                                    href={
                                      "/opportunity?opportunity_id=" + opp._id
                                    }
                                  >
                                    {opp.opportunity_name}
                                  </a>
                                </h4>{" "}
                                {opp.trial_start_date !== undefined &&
                                  !opp.is_paying &&
                                  new Date(
                                    Moment(opp.trial_start_date).add(
                                      opp.trial_period_length,
                                      "days"
                                    )
                                  ) > Date.now() && (
                                    <br />
                                    // <p
                                    //   style={{ float: "right", color: "green" }}
                                    // >
                                    //   Free Trial:
                                    //   {Moment.duration(
                                    //     Moment(opp.trial_start_date)
                                    //       .add(opp.trial_period_length, "days")
                                    //       .diff(Moment(Date.now()))
                                    //   )
                                    //     .asDays()
                                    //     .toFixed(0)}{" "}
                                    //   days remaining
                                    // </p>
                                  )}
                              </span>
                              {opp.venue_location !== undefined &&
                                opp.venue_location.length > 0 && (
                                  <p>
                                    <i
                                      class="fa fa-map-marker"
                                      aria-hidden="true"
                                    ></i>
                                    {opp.venue_location}
                                  </p>
                                )}
                              <p>{opp.opportunity_description}</p>
                              <div
                                style={{
                                  width: "100%",
                                  height: "80px",
                                  background: "black",
                                  display: "flex",
                                }}
                              >
                                <h3 style={{ color: "white", margin: "auto" }}>
                                  Subscription has expired. Click to{" "}
                                  <a href="https://subscriptions.zoho.com/subscribe/0f93bcec85a375cb8630e7e3c27329d5a7726ba08b65d6ccad482bf477cf719e/PBP">
                                    resubscribe
                                  </a>
                                  .
                                </h3>
                              </div>
                              <div class="card__bottom">
                                <div class="options">
                                  {opp.deadline !== null && (
                                    <span class="date">
                                      <i
                                        class="fa fa-calendar"
                                        aria-hidden="true"
                                      ></i>
                                      Deadline:{" "}
                                      {Moment(opp.deadline).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                      )}
                                    </span>
                                  )}
                                  {opp.start_intake_date !== null && (
                                    <span class="date">
                                      <i
                                        class="fa fa-calendar"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Start Date:{" "}
                                      {Moment(opp.start_intake_date).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                      )}
                                    </span>
                                  )}
                                  {opp.end_intake_date !== null && (
                                    <span class="date">
                                      <i
                                        class="fa fa-calendar"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      End Date:{" "}
                                      {Moment(opp.end_intake_date).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                      )}
                                    </span>
                                  )}
                                </div>
                                <div class="card__price">{opp.price}</div>
                              </div>
                              <div className="__footer">
                                {opp.has_applicant_room !== undefined &&
                                  opp.has_applicant_room !== null &&
                                  opp.has_applicant_room !== false && (
                                    <button
                                      type="button"
                                      disabled={true}
                                      class="btn btn-sm btn-white"
                                    >
                                      {" "}
                                      Applicant Room{" "}
                                    </button>
                                  )}

                                <button
                                  type="button"
                                  disabled={true}
                                  disable={true}
                                  class="btn btn-sm btn-white"
                                >
                                  {" "}
                                  Edit{" "}
                                </button>

                                <button
                                  onClick={(event) => {
                                    this.props.deleteOpportunity(
                                      event,
                                      opp._id
                                    );
                                  }}
                                  type="button"
                                  class="btn btn-sm btn-danger"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </DisplayToggle>
              <DisplayToggle
                show={
                  this.props.opportunities_owned === undefined ||
                  this.props.opportunities_owned === null ||
                  this.props.opportunities_owned === null ||
                  this.props.opportunities_owned.length === 0
                }
              >
                <br />
                <h5 style={{ textAlign: "center" }}>
                  You do not have any opportunities
                </h5>
              </DisplayToggle>
              <div class="opp-create-btn">
                <a href="/create_opportunity" class="btn btn-primary">
                  Create Opportunity
                </a>
              </div>
              <br />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default CustomizedTable;
