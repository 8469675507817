/* (2022-08-30) - This page has taken over as the only sign-in page to the network. The sign in pop-up has been removed.
*/
import React from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import '../main.css';
import './sign_in.scss';
import { AppContext } from '../App'
import { Button, Alert, FormControl, FormLabel } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import URL from 'url-parse';
import Navigation from './components/navbar'
import { Register, Forgot_Password, Alert_Message_To_User } from './components/popups'
import SideMenu from './SideMenu';
import img1 from '../../src/img/unicorn.png'
import img2 from '../../src/img/watermarksffinal.png'


class Sign_In_Container extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Sign_In
        {...this.props}
        {...this.context} />
    )
  }
}
Sign_In_Container.contextType = AppContext;

class Sign_In extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      url: new URL(window.location.href, true),
      community_login:false,
      community_login_global:false,
    //   username: '',
    //   password: '',
    //
    //   loggedIn: false,
    //   url: new URL(this.props.location.pathname + this.props.location.search, true),
    //
    //   showAlert: false,
    //
    }
  }
  componentDidMount(){
    if (this.state.url.query.commaff !== undefined && this.state.url.query.commaff.length > 0 && this.state.url.query.response !== undefined && this.state.url.query.response === 'Approved'){
      this.setState({
        community_login:true
      })
    }
    if (this.state.url.query.community_id !== undefined && this.state.url.query.user_id !== undefined){
      this.setState({
        community_login_global:true
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.community_login===false && this.state.community_login_global===false){
      if (Array.isArray(this.props.companies_owned)) {
        if (this.props.companies_owned.length > 0) {
          this.props.history.push(`/profile?company_id=${this.props.companies_owned[0].company}`)
        } else {
          this.props.history.push('/newsfeed')
        }
      } else if (this.props.isLoggedIn === true){
        this.props.history.push('/newsfeed')
      }
    } else {
      if (Array.isArray(this.props.companies_owned)) {
        if (this.props.companies_owned.length > 0) {

        } else {
          this.props.history.push('/newsfeed')
        }
      } else if (this.props.isLoggedIn === true){
        this.props.history.push('/newsfeed')
      }

    }
  }

  validateErrors = (err, field) => {
    if (err[field] !== undefined) {
      return <div className="red-text show">
               { err[field] }
             </div>
    }
  }


  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Funding Simplified - StartupFuel
          </title>
        </Helmet>
        { this.props.showAlert ?
          <Alert bsStyle="success">
            New User
            { this.props.submittedName } has been added to the database!
          </Alert> : <div></div> }
        {/* <Navigation /> */}
        <Forgot_Password />
        <Alert_Message_To_User
          show={this.props.showPopupMessage}
          handleChange={this.props.handleChange}
          dismiss={this.props.dismissModal}
          alert_message={this.props.alert_message}
          {...this.state} />
        <div class="signin_container">
          <div class="signin_cover">
            <img alt="" src={img1} />
          </div>
          <div class="main_signin">
            <div class="side_signin_form">
              <Link to="/">
             <img alt="" src={img2} />
             </Link>
              <div class="panel-heading">
                <div class="panel-title text-center">
                  <h1 class="title">Welcome Back</h1>
                  <p className="text-muted my-2">
                    Sign in to the network!
                  </p>
                  <hr className="mb-5" />
                </div>
              </div>
            </div>
            <div className="signin_form">
                <FormLabel>
                  Email
                </FormLabel>
                <FormControl
                  type="email"
                  value={ this.props.email }
                  placeholder="User Email"
                  name='email'
                  onChange={ this.props.handleChange }
                  className="form-control mb-3 block-example border" />
                <FormLabel>
                  Password
                </FormLabel>
                { /*this.validateErrors(this.props.errors, 'password')*/ }
                <FormControl
                  type="password"
                  value={ this.props.password }
                  placeholder="User Password"
                  name='password'
                  onChange={ this.props.handleChange }
                  className="form-control mb-3 block-example border" />
                { (this.props.loginError !== null || this.props.loginError !== undefined) &&
                  <span className="text-danger">{ this.props.loginError }</span> }
                  {(this.state.community_login === false && this.state.community_login_global===false)&&
                <Button
                  type="submit"
                  onClick={ this.props.handleAuth }
                  className="btn btn-primary btn-lg btn-block login-button my-1">
                  Sign In
                </Button>

              }

              {this.state.community_login === true &&
              <Button
                type="submit"
                onClick={ (event)=>this.props.handleAuthWithCommunity(event,this.state.url.query.commaff) }
                className="btn btn-primary btn-lg btn-block login-button my-1">
                Sign In & Join Community
              </Button>
            }
            {this.state.community_login_global === true &&
            <Button
              type="submit"
              onClick={ (event)=>this.props.handleAuthWithCommunityGlobalShare(event,this.state.url.query.community_id,this.state.url.query.user_id) }
              className="btn btn-primary btn-lg btn-block login-button my-1">
              Sign In & Join Community
            </Button>
          }
                <p
                  style={ { color: "blue"} }
                  className="login-forgot-pass-btn"
                  onClick={ this.props.toggleForgotPassPopup }>
                  Forgot Password?
                </p>
                { this.props.loggedIn ?
                  <Button
                    type="submit"
                    onClick={ this.props.handleLogOut }
                    className="btn btn-primary btn-lg btn-block login-button mb-0">
                    Logout
                  </Button>
                  : <div></div> }
              </div>
              <hr/>
              <div>
                <Link to="/register">
                  <Button
                    className="signup_button">
                    Register
                  </Button>
                </Link>
            </div>
            </div>
            
        </div>
      </React.Fragment>
    )
  }
}

export default Sign_In_Container;
