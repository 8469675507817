import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AragonBodyPart.css";
import AragonCards from "./AragonCards/AragonCards";

const AragonBodyPart = (props) => {
  const [data, setData] = useState({
    dealsReviewed: 0,
    investmentsMade: 0,
    totalReviews: 0,
    numberOfPortfolioCompanies: 0,
  });
  useEffect(() => {
    var a =  "0"; //"63249a1a47c90756b4f4dda1";
    axios.get(
      `fetch_submitted_companies_for_opportunity?opportunity_id=${a}`
    ).then((res) => {

      let dealsReviewedCount = 0;
      let investmentsMadeCount = 0;
      let totalReviewsCount = 0;
      let companies = res.data.full_company_list_with_flags;

      companies.map((company) => {
        console.log("map loopd");
        if (company.reviews.length > 0) {
          dealsReviewedCount++;
          totalReviewsCount += company.reviews.length;
        }
        if (company.application_status === "offer") {
          investmentsMadeCount++;
        }
      });
      setData({
        dealsReviewed: dealsReviewedCount,
        investmentsMade: investmentsMadeCount,
        totalReviews: totalReviewsCount,
        numberOfPortfolioCompanies: companies.length,
      });
    });
  }, []);

  return (
    <div className="AragonBodyPart">
      <div className="AragonFlex ArgonHomeLinks">
        <h2>Default</h2>
        <i className="material-icons">house</i>
        <span className="ArgonHyfon">-</span>
        <b>Dashboards</b>
        <span className="ArgonHyfon">-</span>
        <b>Default</b>
        <div className="ml-a">
          <button>New</button>
          <button>Filters</button>
        </div>
      </div>
      <div className="AragonFlex AragonCardsParentMain">
        <div className="AragonCardsMR AragonCardsParent">
          <AragonCards
            title="DEALS REVIEWED"
            numbers={data.dealsReviewed}
            className="orangeGre"
            icon="thumb_up"
            className2="AragonCardsMR"
            fallbackText="No Deals added, "
            fallbackUrl="/funding"
            fallbackUrlText="add a deal"
          />
          <AragonCards
            title="INVESTMENTS MADE"
            numbers={data.investmentsMade}
            className="orangeGre2"
            icon="pie_chart"
            className2="AragonCardsML"
          />
        </div>
        <div className="AragonCardsML AragonCardsParent">
          <AragonCards
            title="TOTAL REVIEWS"
            numbers={data.totalReviews}
            className="tealGre"
            icon="money"
            className2="AragonCardsMR"
          />
          <AragonCards
            title="NUMBER OF DEALS"
            numbers={data.numberOfPortfolioCompanies}
            className="blueGre"
            icon="bar_chart"
            className2="AragonCardsML"
          />
        </div>
      </div>
    </div>
  );
};

export default AragonBodyPart;
