import React, { Component } from "react";
import axios from "axios";
import io from "socket.io-client";
import GoogleAnalytics from "./gtag";
const socket = io.connect();

export const AppContext = React.createContext({
  user: {
    _id: null,
    loading: true,
  },
  isLoggedIn: null,
  companyData: null,
  handleLogOut: () => {},
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      socket: socket,
      forgot_pass_msg: "",
      alert_message: "",
      showPopupMessage: false,
      showSignInPopup: false,
      showForgotPass: false,
      showSignUpPopup: false,
      showRegisterPopup: false,
      communities_as_member: [],
      user: {
        _id: null,
        first_name: "",
        last_name: "",
        files: [],
        loading: true,
      },

      companies_owned: null,

      isLoggedIn: null,
      opportunities_judging: "",

      handleChange: (e) => {
        const name = e.target.name;
        this.setState({
          [name]: e.target.value,
        });
      },

      handleEmailChange: (e) => {
        const name = e.target.name;
        this.setState({ user: { ...this.state.user, [name]: e.target.value } });
      },

      toggleSignUpPopup: (e) => {
        this.setState({
          showSignUpPopup: !this.state.showSignUpPopup,
          showSignInPopup: this.state.showSignUpPopup === false && false,
          showRegisterPopup: this.state.showSignUpPopup === false && false,
        });
      },

      toggleRegisterPopup: (e) => {
        this.setState({
          showRegisterPopup: !this.state.showRegisterPopup,
          showSignUpPopup: this.state.showRegisterPopup === false && false,
          showSignInPopup: this.state.showRegisterPopup === false && false,
        });
      },

      toggleSignInPopup: (e) => {
        this.setState({
          showSignInPopup: !this.state.showSignInPopup,
          showSignUpPopup: this.state.showSignInPopup === false && false,
          showRegisterPopup: this.state.showSignInPopup === false && false,
        });
      },
      toggleForgotPassPopup: (e) => {
        this.setState({
          showForgotPass: !this.state.showForgotPass,
          showSignInPopup: false,
          showSignUpPopup: false,
          showRegisterPopup: false,
        });
      },
      reset_pass_email: (e) => {
        e.preventDefault();
        axios
          .post(`/forgot_pass`, {
            email: this.state.email,
          })
          .then((response) => {
            if (response.data.status === "error") {
              this.setState({
                forgot_pass_msg: response.data.message,
              });
            } else {
              this.setState({
                showForgotPass: false,
                alert_message:
                  "An email has been sent with password reset instructions.",
                showPopupMessage: true,
              });
            }
          });
      },

      dismissModal: (e, name) => {
        e.target.className === "modal1" &&
          this.setState({
            [name]: false,
            showPopupMessage: false,
          });
      },

      handleLogOut: () => {
        axios
          .get(`/logout`)
          .then((response) => {
            if (response.data.status === "success") {
              this.setState(
                {
                  user: {
                    _id: null,
                    first_name: "",
                    last_name: "",
                    files: [],
                    loading: false,
                  },
                  isLoggedIn: false,
                  companies_owned: [0],
                  opportunities_judging: "",
                },
                // Maaz - Added Auth under then block
                () => {
                  window.location = "/services";
                },
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });

        //window.location.reload();
      },
      persistUser: (user) => {
        // this.sendGetRequest('/companies_user_owns', function(data, _this){
        //
        //   if (data.status === 'success' || data.status === 'partial'){
        this.setState(
          {
            //companies_owned: data.result,
            user: {
              loading: false,
              _id: user._id,
              first_name: user.first_name,
              last_name: user.last_name,
              files: [],
              email: user.email,
            },
            isLoggedIn: true,
            username: "",
            password: "",
          },
          function () {
            this.sendGetRequest("/companies_user_owns", function (data, _this) {
              if (data.status === "success" || data.status === "partial") {
                _this.setState(
                  {
                    companies_owned: data.result,
                    companyData: data.result[1][0],
                  },
                  () => {
                    console.log("here");
                    _this.state.socket.emit(
                      "USER_CONNECTED",
                      _this.state.companies_owned[1][0]._id,
                    );
                  },
                );
              }
            });
          },
        );
        //   }
        // })
      },
      handleAuth: (e) => {
        e.preventDefault();
        axios
          .post("/login", {
            username: this.state.email,
            password: this.state.password,
          })
          .then((response) => {
            if (response.data.status === "login error") {
              this.setState({
                loginError: response.data.message,
              });
            }
            if (
              response.data.status === "success" ||
              response.data.status === "partial"
            ) {
              this.sendGetRequest(
                "/companies_user_owns",
                function (data, _this) {
                  if (data.status === "success" || data.status === "partial") {
                    _this.setState({
                      companies_owned: data.result,
                      user: {
                        loading: false,
                        _id: response.data.result.current_user_id,
                        first_name:
                          response.data.result.current_user_first_name,
                        last_name: response.data.result.current_user_last_name,
                        files: response.data.result.current_user_files,
                      },
                      isLoggedIn: true,
                      username: "",
                      password: "",
                      companyData: data.result[1][0],
                    });
                    _this.state.socket.emit(
                      "USER_CONNECTED",
                      _this.state.companies_owned[1][0]._id,
                    );
                    axios
                      .get(
                        `/fetch_a_company_communities?applicant_company=${data.result[1][0]._id}`,
                      )
                      .then((response) => {
                        if (response.data.status === "success") {
                          _this.setState({
                            communities_as_member: response.data.communities,
                          });
                        }
                        console.log(response);
                      });
                  } else {
                    _this.setState({
                      user: {
                        loading: false,
                        _id: response.data.result.current_user_id,
                        first_name:
                          response.data.result.current_user_first_name,
                        last_name: response.data.result.current_user_last_name,
                        files: response.data.result.current_user_files,
                      },
                      isLoggedIn: true,
                      username: "",
                      password: "",
                    });
                  }
                },
              );
              axios
                .get(
                  `/fetch_opportunities_for_judges?user=${response.data.result.current_user_id}`,
                )
                .then((response) => {
                  if (response.data.status === "success") {
                    this.setState({
                      opportunities_judging: response.data.data,
                    });
                  }
                  console.log(response);
                });
            } //else {
            // this.setState({
            //   username: '',
            //   password: '',
            //   errors: {
            //     email: response.data.result.errors.email
            //   }
            // })
            //
          })
          .catch((error) => {
            console.log(error);
          });
      },
      handleAuthWithCommunity: (e, commaff) => {
        e.preventDefault();
        axios
          .post("/login", {
            username: this.state.email,
            password: this.state.password,
          })
          .then((response) => {
            if (response.data.status === "login error") {
              this.setState({
                loginError: response.data.message,
              });
            }
            if (
              response.data.status === "success" ||
              response.data.status === "partial"
            ) {
              this.sendGetRequest(
                "/companies_user_owns",
                function (data, _this) {
                  if (data.status === "success" || data.status === "partial") {
                    _this.setState(
                      {
                        companies_owned: data.result,
                        user: {
                          loading: false,
                          _id: response.data.result.current_user_id,
                          first_name:
                            response.data.result.current_user_first_name,
                          last_name:
                            response.data.result.current_user_last_name,
                          files: response.data.result.current_user_files,
                        },
                        isLoggedIn: true,
                        companyData: data.result[1][0],
                        username: "",
                        password: "",
                      },
                      () => {
                        axios
                          .post("/community_email_response", {
                            community_applicant:
                              _this.state.companies_owned[1][0]._id,
                            applicant_response: "Approved",
                            commaff: commaff,
                          })
                          .then((response) => {
                            if (response.data.status === "success") {
                              window.location.href = `/community_members?community_id=${response.data.community_id}`;
                            }
                          });
                      },
                    );
                  } else {
                    _this.setState({
                      user: {
                        loading: false,
                        _id: response.data.result.current_user_id,
                        first_name:
                          response.data.result.current_user_first_name,
                        last_name: response.data.result.current_user_last_name,
                        files: response.data.result.current_user_files,
                      },
                      isLoggedIn: true,
                      username: "",
                      password: "",
                    });
                  }
                },
              );
              axios
                .get(
                  `/fetch_opportunities_for_judges?user=${response.data.result.current_user_id}`,
                )
                .then((response) => {
                  if (response.data.status === "success") {
                    this.setState({
                      opportunities_judging: response.data.data,
                    });
                  }
                  console.log(response);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },

      handleAuthWithCommunityGlobalShare: (e, community, user) => {
        e.preventDefault();
        axios
          .post("/login", {
            username: this.state.email,
            password: this.state.password,
          })
          .then((response) => {
            if (response.data.status === "login error") {
              this.setState({
                loginError: response.data.message,
              });
            }
            if (
              response.data.status === "success" ||
              response.data.status === "partial"
            ) {
              this.sendGetRequest(
                "/companies_user_owns",
                function (data, _this) {
                  if (data.status === "success" || data.status === "partial") {
                    _this.setState(
                      {
                        companies_owned: data.result,
                        user: {
                          loading: false,
                          _id: response.data.result.current_user_id,
                          first_name:
                            response.data.result.current_user_first_name,
                          last_name:
                            response.data.result.current_user_last_name,
                          files: response.data.result.current_user_files,
                        },
                        isLoggedIn: true,
                        username: "",
                        password: "",
                        companyData: data.result[1][0],
                      },
                      () => {
                        axios
                          .post("/global_share_has_company", {
                            applicant_company:
                              _this.state.companies_owned[1][0]._id,
                            user_id: user,
                            opp_company: community,
                          })
                          .then((response) => {
                            if (
                              response.data.status === "success" ||
                              response.data.status === "already exists"
                            ) {
                              window.location.href = `/community_members?community_id=${community}`;
                            }
                          });
                      },
                    );
                  } else {
                    _this.setState({
                      user: {
                        loading: false,
                        _id: response.data.result.current_user_id,
                        first_name:
                          response.data.result.current_user_first_name,
                        last_name: response.data.result.current_user_last_name,
                        files: response.data.result.current_user_files,
                      },
                      isLoggedIn: true,
                      username: "",
                      password: "",
                    });
                  }
                },
              );
              axios
                .get(
                  `/fetch_opportunities_for_judges?user=${response.data.result.current_user_id}`,
                )
                .then((response) => {
                  if (response.data.status === "success") {
                    this.setState({
                      opportunities_judging: response.data.data,
                    });
                  }
                  console.log(response);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    };
  }

  sendGetRequest = (url, callback) => {
    axios
      .get(url)
      .then((response) => {
        callback(response.data, this);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getcompanyUrl = (array) => {
    if (Array.isArray(array) && array.length > 0) {
      if (array.length !== 0) {
        return `/profile?company_id=${array[0].company}`;
      } else {
        return "/";
      }
    } else {
      return "/";
    }
  };
  componentDidMount() {
    this.sendGetRequest("/fetch_user_details", function (data, _this) {
      if (data.status === "success" || data.status === "partial") {
        _this.setState(
          {
            user: {
              loading: false,
              _id: data.result.current_user_id,
              first_name: data.result.current_user_first_name,
              last_name: data.result.current_user_last_name,
              files: data.result.current_user_files,
              email: data.result.current_user_email,
              // don't set isLoggedIn here, this trips a chain reaction to child components
            },
          },
          function () {
            this.sendGetRequest("/companies_user_owns", function (data, _this) {
              if (data.status === "success" || data.status === "partial") {
                _this.setState(
                  {
                    companies_owned: data.result,
                    isLoggedIn: true,
                    companyData: data.result[1][0],
                  },
                  () => {
                    axios
                      .get(
                        `/fetch_a_company_communities?applicant_company=${data.result[1][0]._id}`,
                      )
                      .then((response) => {
                        if (response.data.status === "success") {
                          _this.setState({
                            communities_as_member: response.data.communities,
                          });
                        }
                        console.log(response);
                      });

                    _this.state.socket.emit(
                      "USER_CONNECTED",
                      _this.state.companies_owned[1][0]._id,
                    );
                  },
                );
              } else {
                // no companies, but still logged in
                _this.setState({
                  isLoggedIn: true,
                });
              }
            });
            axios
              .get(
                `/fetch_opportunities_for_judges?user=${this.state.user._id}`,
              )
              .then((response) => {
                if (response.data.status === "success") {
                  this.setState({
                    opportunities_judging: response.data.data,
                  });
                }
                console.log(response);
              });
            axios.get(`/companies_user_owns2`).then((response) => {
              console.log("companies_user_owns2");
              console.log(response);
            });
          },
        );
      } else if (data.status === "redirect") {
        _this.setState({
          user: {
            loading: false,
          },
          isLoggedIn: false,
        });
      }
    });
  }

  render() {
    return (
      <div>
        <GoogleAnalytics />
        <AppContext.Provider value={this.state}>
          {this.props.children}
        </AppContext.Provider>
      </div>
    );
  }
}

export default App;
