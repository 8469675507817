import React from "react";
import classNames from "classnames";

const ApplicationInfoComponent = ({ applicationObject }) => {
  // Check if there are any non-empty answers in the applicationObject
  const hasApplicationInfo = Object.keys(applicationObject).some(
    (key) =>
      applicationObject[key].Answer &&
      applicationObject[key].Answer.trim() !== "",
  );

  return (
    <div>
      <h4>Application Info</h4>
      <p className="text-grey text-sm">Application submission details</p>
      <div className="review-content-card">
        {hasApplicationInfo ? (
          <table className="table-fixed w-100">
            <tbody>
              {Object.keys(applicationObject)
                .filter(
                  (key) =>
                    applicationObject[key].Answer &&
                    applicationObject[key].Answer.trim() !== "",
                )
                .map((key, index, array) => {
                  const isLastItem = index === array.length - 1;
                  return (
                    <tr
                      className={classNames("border-gray border-t", {
                        "border-bottom": !isLastItem,
                      })}
                      key={key}
                    >
                      <td className="py-4 font-bold text-sm">
                        {applicationObject[key].Title}
                      </td>
                      <td className="py-4 text-sm">
                        {applicationObject[key].Answer}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <p className="text-grey text-sm">
            No application information available.
          </p>
        )}
      </div>
    </div>
  );
};

export default ApplicationInfoComponent;
