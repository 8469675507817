/* (2019-06-11) - This is the settings page of the SIR/Application room, it
allows you to configure various settings for the SIR. A lot of the logic is
handled on other pages, this mainly just accepts the checkbox.
*/
import React, { Component } from "react";
import axios from "axios";
import URL from "url-parse";
import { Success_Popup } from "../../../components/popups";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import "./index.scss";
import { Link } from "react-router-dom";
import { NavItem } from "reactstrap";
import Navigation from "../../../components/navbar_pb";
import ArgNav from "../argNav";
import SettingsNavigation from "./settings-sidenav";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      judges: [1],
      judges_can_register: false,
      users_can_see_feedback: false,
      additional_review_options: true,
      additional_review_criteria: [],
      temp_value: "",
      success_msg: "Saved!",
      show_success_popup: false,
      judges_get_email: false,
      opportunity_private: false,
      limit_number_of_reviews: false,
      number_of_reviews: null,
      has_applicant_registration_email: false,
      applicant_registration_email: {
        header: "Thank you for applying!",
        body: `We appreciate you taking the time to apply to our application.
              We will get back to your shortly regarding how you did.
              `,
      },
      opportunity_form_modify: false,
      custom_text_q1: "",
      custom_text_q2: "",
      opportunity_form: [],

      has_judge_specialty: false,
      judge_specialty_value: "fintech",
      show_judge_on_opportunity: false,
    };
  }
  componentDidMount() {
    axios
      .get(
        `fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          ...response.data.result,
        });
      });
    axios
      .get(
        `/fetch_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          ...response.data.existing_opportunity_form,
        });
      });
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        judges: this.props.judges,
      });
    }
  }

  displaySaved = () => {
    this.setState(
      {
        show_success_popup: true,
      },
      () => {
        setTimeout(() => this.setState({ show_success_popup: false }), 1500);
      },
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleEmailChange = (e) => {
    const name = e.target.name;
    this.setState({
      applicant_registration_email: {
        ...this.state.applicant_registration_email,
        [name]: e.target.value,
      },
    });
  };
  handleNumberReviewsSave = (e) => {
    axios.post(
      `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        judges_can_register: this.state.judges_can_register,
        users_can_see_feedback: this.state.users_can_see_feedback,
        judges_get_email: this.state.judges_get_email,
        limit_number_of_reviews: this.state.limit_number_of_reviews,
        number_of_reviews: this.state.number_of_reviews,
        opportunity_private: this.state.opportunity_private,
        opportunity_form_modify: this.state.opportunity_form_modify,
        has_applicant_registration_email:
          this.state.has_applicant_registration_email,
      },
    );
    this.displaySaved();
  };

  handleCheck = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: !this.state[name],
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
            has_judge_specialty: this.state.has_judge_specialty,
            show_judge_on_opportunity: this.state.show_judge_on_opportunity,
          },
        );
        this.displaySaved();
      },
    );
  };
  handleCustomForm = (e, key) => {
    const name = e.target.name;
    let target_key = this.state.opportunity_form[key];
    if (
      name === "display" &&
      this.state.opportunity_form[key]["display"] === true
    ) {
      let obj = {
        ...this.state.opportunity_form[key],
        ["required"]: false,
        ["display"]: false,
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    } else {
      let obj = {
        ...this.state.opportunity_form[key],
        [name]: !this.state.opportunity_form[key][name],
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    }
  };
  handleFormSave = (e) => {
    axios.post(
      `/edit_opportunity_form?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        opportunity_form: this.state.opportunity_form,
      },
    );
    this.displaySaved();
  };
  handleEmailSave = (e) => {
    axios.post(
      `/edit_opportunity_email?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        applicant_registration_email: {
          header: this.state.applicant_registration_email.header,
          body: this.state.applicant_registration_email.body,
        },
      },
    );
    this.displaySaved();
  };

  handleSave = (e) => {
    axios.post(
      `/create_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        custom_text_q1: this.state.custom_text_q1,
        custom_text_q2: this.state.custom_text_q2,
      },
    );
  };

  addCriteria = (e) => {
    this.setState(
      {
        additional_review_criteria: [
          ...this.state.additional_review_criteria,
          this.state.temp_value,
        ],
        temp_value: "",
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
          },
        );
        this.displaySaved();
      },
    );
  };
  deleteCriteria = (e, value) => {
    let newList = this.state.additional_review_criteria.splice(
      this.state.additional_review_criteria.indexOf(value),
      1,
    );
    this.setState(
      {
        additional_review_criteria:
          this.state.additional_review_criteria.filter((x) => x !== value),
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
          },
        );
        this.displaySaved();
        axios.post(
          `/edit_rating_criteria_for_opp?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            additional_review_criteria: this.state.additional_review_criteria,
            value: value,
          },
        );
      },
    );
  };

  render() {
    const { url } = this.state;
    return (
      <div>
        <Navigation />
        <ArgNav
          opportunity_name={this.props.opportunity_name}
          opportunity_id={this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname}
        />
        <div class="_content">
          <React.Fragment>
            <Success_Popup
              show={this.state.show_success_popup}
              alert_message={this.state.success_msg}
            />
            <div className="__application-room-settings">
              <div className="__application-room-padding __header">
                <h3>Team Settings</h3>
              </div>
              <div className="__pb_settings_page">
                <SettingsNavigation />

                <div className="__main __application-room-padding">
                  <label>
                    <Checkbox
                      checked={this.state.judges_can_register}
                      onChange={(event) => {
                        this.handleCheck(event);
                      }}
                      name="judges_can_register"
                      color="primary"
                    />
                    <p className="__settings_text">Enable Team Invite Link</p>
                  </label>
                  {this.state.judges_can_register && (
                    <p className="__paragraph">
                      Team Invite Link: (Copy and share this link with anyone
                      you would like to join your PitchBox) <br />{" "}
                      <Link
                        className="__link"
                        to={`/applicant_room_team?opportunity_id=${this.state.url.query.opportunity_id}`}
                      >
                        <span>
                          {this.state.url.origin}
                          /applicant_room_team?opportunity_id=
                          {this.state.url.query.opportunity_id}
                        </span>
                      </Link>
                    </p>
                  )}

                  <label>
                    <Checkbox
                      checked={this.state.judges_get_email}
                      onChange={(event) => {
                        this.handleCheck(event);
                      }}
                      name="judges_get_email"
                      color="primary"
                    />
                    <p className="__settings_text">
                      Email Team Members When Added
                    </p>
                  </label>
                  <label>
                    <Checkbox
                      checked={this.state.show_judge_on_opportunity}
                      onChange={(event) => {
                        this.handleCheck(event);
                      }}
                      name="show_judge_on_opportunity"
                      color="primary"
                    />
                    <p className="__settings_text">
                      Show Team Members On Application Portal
                    </p>
                  </label>
                  {/*                <label>*/}
                  {/*                  <Checkbox*/}
                  {/*                    checked={this.state.has_judge_specialty}*/}
                  {/*                    onChange={(event) => { this.handleCheck(event) }}*/}
                  {/*                    name="has_judge_specialty"*/}
                  {/*                    color="primary"*/}
                  {/*                  />*/}
                  {/*                <p className='__settings_text'>Enable Team Roles*/}
                  {/*</p>              </label>*/}
                  {/*                {this.state.has_judge_specialty &&*/}
                  {/*                  <div>*/}
                  {/*                    <FormControl*/}
                  {/*                      variant="outlined"*/}
                  {/*                      >*/}
                  {/*                      <InputLabel*/}
                  {/*                        ref={ref => {*/}
                  {/*                          this.InputLabelRef = ref;*/}
                  {/*                        }}*/}
                  {/*                        htmlFor="outlined-sortby-native-simple">*/}
                  {/*                        Team Role Template:*/}
                  {/*                      </InputLabel>*/}
                  {/*                      <Select*/}
                  {/*                        native*/}
                  {/*                        value={this.state.judge_specialty_value}*/}
                  {/*                        onChange={this.handleChange}*/}
                  {/*                        input={<OutlinedInput*/}
                  {/*                          name="sortby"*/}
                  {/*                          labelWidth={this.state.labelWidth}*/}
                  {/*                          id="outlined-sortby-native-simple" />}>*/}

                  {/*                        <option value={"fintech"}>*/}
                  {/*                          Venture Capital Fund*/}
                  {/*                        </option>*/}
                  {/*                        <option value={"fintech"}>*/}
                  {/*                          Angel Syndicate*/}
                  {/*                        </option>*/}
                  {/*                        <option value={"fintech"}>*/}
                  {/*                          Accelerator/Incubator*/}
                  {/*                        </option>*/}
                  {/*                        <option value={"fintech"}>*/}
                  {/*                          Venture Debt Lender*/}
                  {/*                        </option>*/}
                  {/*                        <option value={"fintech"}>*/}
                  {/*                          Private Equity*/}
                  {/*                        </option>*/}
                  {/*                        <option value={"fintech"}>*/}
                  {/*                          Corporate Innovation*/}
                  {/*                        </option>*/}
                  {/*                        <option value={"fintech"}>*/}
                  {/*                          Angel Investor*/}
                  {/*                        </option>*/}
                  {/*                        <option value={"fintech"}>*/}
                  {/*                          SPV*/}
                  {/*                        </option>*/}
                  {/*                        <option value={"fintech"}>*/}
                  {/*                          Custom*/}
                  {/*                        </option>*/}

                  {/*                      </Select>*/}
                  {/*                    </FormControl>*/}
                  {/*                  </div>*/}
                  {/*                }*/}
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Page;
