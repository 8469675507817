const create_opp_questions = [
  {
    type: "card_select",
    title: "Select the type of PitchBox you would like to make:",
    db_value: "opportunity_type",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Hackathon",

      "Angel Groups",
      "Other",
      "Not Applicable",
    ],
    options: [
      {
        value: "Pitch Competitions",
        title: "Pitch Competition",
        subtitle: "This will be listed in the competitions directory.",
        description:
          "A pitch competition facilitates an environment where you can onboard judges, gives reviews to companies, and optionally indicate that there is location with a specific time and place.",
        help: "",
        icon: "PC",
      },
      {
        value: "Hackathon",
        title: "Hackathon",
        subtitle: "Listed in the competitions directory",
        description:
          "Allows companies to submit applications to get into your hackathon. You can onboard your review team to make sure that the right companies get will create the most disruptive tech.",
        help: "",
        icon: "H",
      },
      {
        value: "Innovation Challenges",
        title: "An Innovation Challenge",
        subtitle: "Listed in the competitions directory",
        description:
          "Allows you to invite startups and review technology that is innovative that may be applicable to your business/initiative. Allows you to bring on a review team to ensure that the right people get selected.",
        help: "",
        icon: "IC",
      },
      {
        value: "Accelerator",
        title: "Accelerator Intake",
        subtitle: "Listed in the programs directory",
        description:
          "This allows you to intake potential companies that want to join your accelerator. You can bring on your review team to ensure only the best candidates get in. Defn: Accelerators are fixed-term, cohort-based programs that include seed investment.",
        help: "",
        icon: "A",
      },
      {
        value: "Incubator",
        title: "Incubator Intake",
        subtitle: "Listed in the programs directory",
        description:
          "This allows you to intake potential companies that want to join your incubator. You can bring on your review team to ensure only the best candidates get in. Defn: Incubators help new and startup companies to develop by providing services such as management training or office space.",
        help: "",
        icon: "I",
      },
      {
        value: "Grants",
        title: "Grants",
        subtitle: "Listed in the programs directory",
        description:
          "Are you offering a grant? Do you want your grant to be awarded to the best company? Use this tool to onboard judges that can rate and review the candidates to ensure you select the best one.",
        help: "",
        icon: "GR",
      },
      {
        value: "Debt Financing",
        title: "Debt Financing",
        subtitle: "Listed in the funding directory",
        description:
          "Allows companies to submit applications to receive debt financing from you. You can onboard your review team to make sure that the right companies get selected that can pay off the debt.",
        help: "",
        icon: "DF",
      },
      {
        value: "Equity Financing",
        title: "Equity Financing",
        subtitle: "Listed in the funding directory",
        description:
          "Allows companies to submit applications to receive equity financing from you. You can onboard your review team to make sure that the right companies get selected which will generate the highest ROI.",
        help: "",
        icon: "EF",
      },

      {
        value: "Angel Groups",
        title: "Angel Groups",
        subtitle: "Listed in the funding directory",
        description:
          "Allows companies to submit applications to you and the angels in your network. You can onboard your angels to make sure that the right companies get selected so that your angels are happy with were the funds are being allocated.",
        help: "",
        icon: "AG",
      },
      {
        value: "Other",
        title: "Other",

        description: "Prefered answer not found amoong the provided answers",
        help: "",
        icon: "O",
      },
      {
        value: "Not Applicable",
        title: "Not Applicable",

        description: "None of the available answers apply to your business",
        help: "",
        icon: "NA",
      },
    ],
  },
  {
    type: "single_line",
    title: "Enter a title for your Opportunity:",
    description: "Title",
    db_value: "opportunity_name",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
  },
  {
    type: "single_line",
    title:
      "Enter a website link for your opportunity (applicants can click this to view more information):",
    description: "Website",
    db_value: "website_url",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
  },
  {
    type: "multi_line",
    title: "Enter a description for your Opportunity:",
    description: "Opportunity Description",
    db_value: "opportunity_description",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
  },

  {
    type: "multi_card_select",
    title: "Please select the targeted applicants industry's",
    db_value: "target_industries",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
    options: [
      {
        value: "Agriculture",
        title: "Agriculture",
        description: "",
        icon: "AG",
      },
      {
        value: "AI/ML",
        title: "AI/ML",
        description: "",
        icon: "AI",
      },
      {
        value: "Blockchain",
        title: "Blockchain",
        description: "",
        icon: "B",
      },
      {
        value: "Cannabis",
        title: "Cannabis",
        description: "",
        icon: "420",
      },
      {
        value: "Construction",
        title: "Construction",
        description: "",
        icon: "C",
      },
      {
        value: "COVID-19",
        title: "COVID-19",
        description: "",
        icon: "CO",
      },
      {
        value: "Data",
        title: "Data",
        description: "",
        icon: "D",
      },
      {
        value: "E-Commerce",
        title: "E-Commerce",
        description: "",
        icon: "EC",
      },
      {
        value: "Education",
        title: "Education",
        description: "",
        icon: "ED",
      },
      {
        value: "Employment",
        title: "Employment",
        description: "",
        icon: "EM",
      },
      {
        value: "Energy",
        title: "Energy",
        description: "",
        icon: "EN",
      },
      {
        value: "Enterprise Software",
        title: "Enterprise Software",
        description: "",
        icon: "ES",
      },
      {
        value: "Environmental",
        title: "Environmental",
        description: "",
        icon: "ENV",
      },
      {
        value: "Fashion",
        title: "Fashion",
        description: "",
        icon: "FA",
      },
      {
        value: "Fintech",
        title: "Fintech",
        description: "",
        icon: "FT",
      },
      {
        value: "Fitness",
        title: "Fitness",
        description: "",
        icon: "FIT",
      },
      {
        value: "Food",
        title: "Food",
        description: "",
        icon: "F",
      },
      {
        value: "Gaming",
        title: "Gaming",
        description: "",
        icon: "GA",
      },
      {
        value: "Healthcare",
        title: "Healthcare",
        description: "",
        icon: "HC",
      },
      {
        value: "Hospitality",
        title: "Hospitality",
        description: "",
        icon: "HO",
      },
      {
        value: "IoT",
        title: "IoT",
        description: "",
        icon: "IoT",
      },
      {
        value: "Legal",
        title: "Legal",
        description: "",
        icon: "L",
      },
      {
        value: "Lifestyle",
        title: "Lifestyle",
        description: "",
        icon: "LS",
      },
      {
        value: "Manufacturing",
        title: "Manufacturing",
        description: "",
        icon: "M",
      },
      {
        value: "Marketing",
        title: "Marketing",
        description: "",
        icon: "MA",
      },
      {
        value: "Media",
        title: "Media",
        description: "",
        icon: "ME",
      },
      {
        value: "Photography",
        title: "Photography",
        description: "",
        icon: "P",
      },
      {
        value: "PropTech",
        title: "PropTech",
        description: "",
        icon: "PT",
      },
      {
        value: "Real Estate",
        title: "Real Estate",
        description: "",
        icon: "RE",
      },
      {
        value: "Retail",
        title: "Retail",
        description: "",
        icon: "RET",
      },
      {
        value: "Robotics",
        title: "Robotics",
        description: "",
        icon: "RO",
      },
      {
        value: "Security",
        title: "Security",
        description: "",
        icon: "SEC",
      },
      {
        value: "Social Media",
        title: "Social Media",
        description: "",
        icon: "SM",
      },
      {
        value: "Space",
        title: "Space",
        description: "",
        icon: "S",
      },
      {
        value: "Sports",
        title: "Sports",
        description: "",
        icon: "SP",
      },
      {
        value: "Telecom",
        title: "Telecom",
        description: "",
        icon: "TC",
      },
      {
        value: "Transportation",
        title: "Transportation",
        description: "",
        icon: "TR",
      },
      {
        value: "Travel",
        title: "Travel",
        description: "",
        icon: "TRA",
      },
      {
        value: "Other",
        title: "Other",
        description: "",
        icon: "O",
      },
      // {
      //   value: "Not Applicable",
      //   title: "Not Applicable",
      //   description: "",
      //   icon: "NA",
      // },
    ],
  },
  {
    type: "multi_card_select",
    title: "Please select the targeted applicants business model:",
    db_value: "target_model_companies",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
    options: [
      {
        value: "B2B",
        title: "Business to Business",
        description: "",
        icon: "B2B",
      },
      {
        value: "B2C",
        title: "Business to Consumer",
        description: "",
        icon: "B2C",
      },
      {
        value: "B2G",
        title: "Business to Government",
        description: "",
        icon: "B2G",
      },
      {
        value: "C2C",
        title: "Consumer to Consumer",
        description: "",
        icon: "C2C",
      },
      // {
      //   value: "Other",
      //   title: "Other",
      //   description: "",
      //   icon: "O",
      // },
      // {
      //   value: "Not Applicable",
      //   title: "Not Applicable",
      //   description: "",
      //   icon: "NA",
      // },
    ],
  },
  {
    type: "multi_card_select",
    title: "Please select the targeted applicants business stage:",
    db_value: "target_stage_companies",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
    options: [
      {
        value: 1.1,
        title: "Pre-Seed",
        subtitle: "< $150K",
        description:
          "A Pre-Seed round is a pre-institutional seed round that either has no institutional investors or is a very low amount, often below $150k.",
        help: "",
        icon: "PS",
      },
      {
        value: 1.2,
        title: "Seed",
        subtitle: "$150k - $2M",
        description:
          "Seed rounds are among the first rounds of funding a company will receive, generally while the company is young and working to gain traction. Round sizes range between $10k–$2M, though larger seed rounds have become more common in recent years. A seed round typically comes after an angel round (if applicable) and before a company’s Series A round.",
        help: "",
        icon: "S",
      },
      {
        value: 2.1,
        title: "Series A",
        subtitle: "$1M–$30M.",
        description:
          "Series A rounds are funding rounds for earlier stage companies and range on average between $1M–$30M.",
        help: "",
        icon: "SA",
      },
      {
        value: 2.2,
        title: "Series B",
        subtitle: "$1M–$30M.",
        description:
          "Series B rounds are similar to Series A, they are just the second round. They range on average between $1M–$30M.",
        help: "",
        icon: "SB",
      },
      {
        value: 2.3,
        title: "Series C",
        subtitle: "$10M+.",
        description:
          "Series C rounds and onwards are for later stage and more established companies. These rounds are usually $10M+ and are often much larger.",
        help: "",
        icon: "SC",
      },
      {
        value: 3,
        title: "IPO",
        subtitle: "",
        description:
          "These are public companies that can be found on the stock market.",
        help: "",
        icon: "IPO",
      },
      // {
      //   value: "Other",
      //   title: "Other",
      //   description: "",
      //   icon: "O",
      // },
      // {
      //   value: "Not Applicable",
      //   title: "Not Applicable",
      //   description: "",
      //   icon: "NA",
      // },
    ],
  },
  {
    type: "multi_card_select",
    title:
      "If your thesis includes a diversity component, please indicate it here below:",
    db_value: "target_distinctions",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
    options: [
      {
        value: "female_founder",
        title: "Female Founders",
        subtitle: "",
        description: "At least one of the founding team members is female.",
        help: "",
        icon: "FF",
      },
      {
        value: "aboriginal",
        title: "Aboriginal Founders",
        subtitle: "",
        description:
          "At least one of the founding team members would consider themselves as an aboriginal.",
        help: "",
        icon: "AF",
      },
      {
        value: "minority",
        title: "Visible Minority Founder",
        subtitle: "",
        description:
          "One of the founding members would consider themselves a minority.",
        help: "",
        icon: "VF",
      },
      {
        value: "lgbtq",
        title: "LGBTQ+ Founders",
        subtitle: "",
        description:
          "At least one of the founding team members would classify themselves as part of the LGBTQ+ community.",
        help: "",
        icon: "LF",
      },
      {
        value: "veteran",
        title: "Veteran Founders",
        subtitle: "",
        description:
          "One of the founding members would consider themselves a minority.",
        help: "",
        icon: "VF",
      },
      {
        value: "disabled",
        title: "Founders of Disability",
        subtitle: "",
        description: "A founding member has a physical or mental disability.",
        help: "",
        icon: "FD",
      },
      {
        value: "Other",
        title: "Other",
        description: "",
        icon: "O",
      },
      {
        value: "Not Applicable",
        title: "Not Applicable",
        description: "",
        icon: "NA",
      },
    ],
  },

  {
    type: "multi_card_select",
    title: "Please select the targeted applicants country's of residence:",
    db_value: "geographical_location",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
    options: [
      {
        value: "Canada",
        title: "Canada",
        description: "",
        icon: "CA",
      },
      {
        value: "United States",
        title: "United States",
        description: "",
        icon: "USA",
      },
      {
        value: "United Kingdom",
        title: "United Kingdom",
        description: "",
        icon: "UK",
      },
      {
        value: "Argentina",
        title: "Argentina",
        description: "",
        icon: "AR",
      },
      {
        value: "Austria",
        title: "Austria",
        description: "",
        icon: "AU",
      },
      {
        value: "Australia",
        title: "Australia",
        description: "",
        icon: "AUS",
      },
      {
        value: "Bangladesh",
        title: "Bangladesh",
        description: "",
        icon: "BA",
      },
      {
        value: "Brazil",
        title: "Brazil",
        description: "",
        icon: "BR",
      },
      {
        value: "Belgium",
        title: "Belgium",
        description: "",
        icon: "BE",
      },
      {
        value: "Cameroon",
        title: "Cameroon",
        description: "",
        icon: "CAM",
      },
      {
        value: "Chile",
        title: "Chile",
        description: "",
        icon: "CHI",
      },
      {
        value: "China",
        title: "China",
        description: "",
        icon: "CH",
      },
      {
        value: "Croatia",
        title: "Croatia",
        description: "",
        icon: "CRO",
      },
      {
        value: "Colombia",
        title: "Colombia",
        description: "",
        icon: "COL",
      },
      {
        value: "Denmark",
        title: "Denmark",
        description: "",
        icon: "DEN",
      },
      {
        value: "Egypt",
        title: "Egypt",
        description: "",
        icon: "EGY",
      },
      {
        value: "Estonia",
        title: "Estonia",
        description: "",
        icon: "EST",
      },
      {
        value: "Finland",
        title: "Finland",
        description: "",
        icon: "FIN",
      },
      {
        value: "France",
        title: "France",
        description: "",
        icon: "FRA",
      },
      {
        value: "Germany",
        title: "Germany",
        description: "",
        icon: "GER",
      },
      {
        value: "Ghana",
        title: "Ghana",
        description: "",
        icon: "GHA",
      },
      {
        value: "Greece",
        title: "Greece",
        description: "",
        icon: "GRE",
      },
      {
        value: "Hong Kong",
        title: "Hong Kong",
        description: "",
        icon: "HK",
      },
      {
        value: "India",
        title: "India",
        description: "",
        icon: "IN",
      },
      {
        value: "Indonesia",
        title: "Indonesia",
        description: "",
        icon: "IND",
      },
      {
        value: "Iran",
        title: "Iran",
        description: "",
        icon: "IR",
      },
      {
        value: "Ireland",
        title: "Ireland",
        description: "",
        icon: "IRE",
      },
      {
        value: "Israel",
        title: "Israel",
        description: "",
        icon: "ISR",
      },
      {
        value: "Italy",
        title: "Italy",
        description: "",
        icon: "ITA",
      },
      {
        value: "Japan",
        title: "Japan",
        description: "",
        icon: "JA",
      },
      {
        value: "Kenya",
        title: "Kenya",
        description: "",
        icon: "KEN",
      },
      {
        value: "Lithuania",
        title: "Lithuania",
        description: "",
        icon: "LIT",
      },
      {
        value: "Malaysia",
        title: "Malaysia",
        description: "",
        icon: "MAL",
      },
      {
        value: "Mexico",
        title: "Mexico",
        description: "",
        icon: "MEX",
      },
      {
        value: "Nepal",
        title: "Nepal",
        description: "",
        icon: "NEP",
      },
      {
        value: "Netherlands",
        title: "Netherlands",
        description: "",
        icon: "NET",
      },
      {
        value: "New Zealand",
        title: "New Zealand",
        description: "",
        icon: "NZ",
      },
      {
        value: "Nigeria",
        title: "Nigeria",
        description: "",
        icon: "NI",
      },
      {
        value: "Norway",
        title: "Norway",
        description: "",
        icon: "NOR",
      },
      {
        value: "Pakistan",
        title: "Pakistan",
        description: "",
        icon: "PAK",
      },
      {
        value: "Peru",
        title: "Peru",
        description: "",
        icon: "PE",
      },
      {
        value: "Philippines",
        title: "Philippines",
        description: "",
        icon: "PH",
      },
      {
        value: "Poland",
        title: "Poland",
        description: "",
        icon: "PO",
      },
      {
        value: "Portugal",
        title: "Portugal",
        description: "",
        icon: "POR",
      },
      {
        value: "Romania",
        title: "Romania",
        description: "",
        icon: "RO",
      },
      {
        value: "Russia",
        title: "Russia",
        description: "",
        icon: "RU",
      },
      {
        value: "Saudi Arabia",
        title: "Saudi Arabia",
        description: "",
        icon: "SA",
      },
      {
        value: "Singapore",
        title: "Singapore",
        description: "",
        icon: "SI",
      },
      {
        value: "South Africa",
        title: "South Africa",
        description: "",
        icon: "SA",
      },
      {
        value: "South Korea",
        title: "South Korea",
        description: "",
        icon: "SK",
      },
      {
        value: "Spain",
        title: "Spain",
        description: "",
        icon: "SP",
      },
      {
        value: "Sri Lanka",
        title: "Sri Lanka",
        description: "",
        icon: "SL",
      },
      {
        value: "Sweden",
        title: "Sweden",
        description: "",
        icon: "SW",
      },
      {
        value: "Switzerland",
        title: "Switzerland",
        description: "",
        icon: "SWI",
      },
      {
        value: "Taiwan",
        title: "Taiwan",
        description: "",
        icon: "TAI",
      },
      {
        value: "Thailand",
        title: "Thailand",
        description: "",
        icon: "THA",
      },
      {
        value: "Turkey",
        title: "Turkey",
        description: "",
        icon: "TU",
      },
      {
        value: "United Arab Emirates",
        title: "United Arab Emirates",
        description: "",
        icon: "UAE",
      },
      {
        value: "Uganda",
        title: "Uganda",
        description: "",
        icon: "UG",
      },
      {
        value: "Ukraine",
        title: "Ukraine",
        description: "",
        icon: "UKR",
      },
      {
        value: "Uruguay",
        title: "Uruguay",
        description: "",
        icon: "URU",
      },
      {
        value: "Vietnam",
        title: "Vietnam",
        description: "",
        icon: "VI",
      },
      {
        value: "Other",
        title: "Other",
        description: "",
        icon: "O",
      },
      // {
      //   value: "Not Applicable",
      //   title: "Not Applicable",
      //   description: "",
      //   icon: "NA",
      // },
    ],
  },

  {
    type: "date",
    title: "Enter the date you would like to start accepting applications:",
    description: "Title",
    db_value1: "start_intake_date",
    db_value1_title: "Select the DAY to go live",
    db_value2: "start_intake_time",
    db_value2_title: "Select the TIME to go live",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
  },
  {
    type: "date",
    title: "Enter a deadline for when all applications should be submitted by:",
    description: "Title",
    db_value1: "end_intake_date",
    db_value1_title: "Select the DAY that this will close for applicants",
    db_value2: "end_intake_time",
    db_value2_title: "Select the TIME that this will close for applicants",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
  },
  {
    type: "single_line_multi",
    title: "Please describe the Cheque Size for the winning applicants:",
    description: "Cheque Size",
    db_value1: "min_cheque_size",
    db_value1_title: "Minimum Check Size",
    db_value2: "max_cheque_size",
    db_value2_title: "Maximum Check Size",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
  },
  {
    type: "multi_line",
    title: "Please describe the prize(s) for the winning applicants:",
    description: "Prize",
    db_value: "prize",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
  },
  {
    type: "single_line",
    title: "Please enter the address for where the event will be held:",
    description: "Venue Address",
    db_value: "venue_location",
    applicable_to: ["Pitch Competitions", "Angel Groups"],
  },

  {
    type: "toggle",
    title: "Would you like to have a PitchBox?",
    description: "Title",
    db_value: "has_applicant_room",
    option_1: true,
    option_1_header: "YES",
    option_1_body:
      "Selecting 'YES' will allow you to receive applications directly through StartupFuel. You will be able to Rate & Review companies, invite committee members/judges & shortlist top applicants.",
    option_2: false,
    option_2_header: "NO",
    option_2_body:
      "Selecting 'NO' means that you will only be listed on StartupFuel.com & plan to use excel or other software to manage applicants. We strongly recommend using our PitchBox as your opportunity will be redirected.",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
  },

  {
    type: "image",
    title: "Upload a cover image:",
    description: "Title",
    db_value: "file",
    applicable_to: [
      "Pitch Competitions",
      "Accelerator",
      "Incubator",
      "Innovation Challenges",
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Angel Groups",
      "Hackathon",
      "Other",
      "Not Applicable",
    ],
  },
];

const country_list = [
  "Canada",
  "United States",
  "China",
  "Japan",
  "Russia",
  "Germany",
  "England",
  "Other",
];
const business_type = ["B2B", "B2C", "B2G", "C2C"];

const opportunity_type = [
  "Pitch Competitions",
  "Accelerator",
  "Incubator",
  "Innovation Challenges",
  "Debt Financing",
  "Equity Financing",
  "Other",
  "Not Applicable",
  /*'Events',
  'Hackathons',
  'Grants',*/
];

const vertical_list = [
  "Payments",
  "Personal Finance",
  "Cryptocurrencies",
  "Blockchain",
  "Cyber Security",
  "Capital Markets",
  "Banking",
  "Lending",
  "Artificial Intelligence",
  "Accounting",
  "RegTech",
  "InsurTech",
  "Real Estate",
];

const opportunity_fields_values = {
  start_date: {
    opportunity_type: ["Events", "Pitch Competitions", "Hackathons"],
    //registration
    label: "Start Date",
    type: "datetime-local",
    value: "start_date",
    placeholder: "Start Date",
    onChange: "handleChange",
  },
  end_date: {
    opportunity_type: ["Events", "Pitch Competitions", "Hackathons"],
    //registration
    label: "End Date",
    type: "datetime-local",
    value: "end_date",
    placeholder: "End Date",
    onChange: "handleChange",
  },
  ticket_price_check: {
    opportunity_type: ["Events"],
    type: "ticket_price_checked",
  },
  ticket_price: {
    opportunity_type: ["Events"],
    //registration
    label: "Ticket Price",
    type: "number",
    value: "ticket_price",
    placeholder: "Ticket Price",
    onChange: "handleChange",
    check_dependent: "yes",
  },
  venue_location: {
    opportunity_type: ["Events"],
    //registration
    label: "Venue Location",
    type: "text",
    value: "venue_location",
    placeholder: "Venue Location",
    onChange: "handleChange",
  },
  deadline: {
    opportunity_type: [
      "Grants",
      "Hackathons",
      "Pitch Competitions",
      "Innovation Challenges",
      "Events",
    ],
    //registration
    label: "Deadline",
    type: "datetime-local",
    value: "deadline",
    placeholder: "Deadline",
    onChange: "handleChange",
  },
  // deadline_to_complete:{
  //   opportunity_type:['Innovation Challenges'],
  //   //registration
  //   label: 'Deadline To Complete',
  //   type:'dateime-local',
  //   value:'deadline_to_complete',
  //   placeholder:'Deadline To Complete',
  //   onChange:'handleChange'
  // },
  amount: {
    opportunity_type: [
      "Debt Financing",
      "Equity Financing",
      "Grants",
      "Hackathons",
    ],
    //registration
    label: "Amount",
    type: "number",
    value: "amount",
    placeholder: "Amount",
    onChange: "handleChange",
  },
  prize_pitch: {
    opportunity_type: ["Pitch Competitions"],
    //registration
    label: "Pitch Prize",
    type: "number",
    value: "prize_pitch",
    placeholder: "Pitch Prize",
    onChange: "handleChange",
  },
  prize_challenge: {
    opportunity_type: ["Innovation Challenges"],
    //registration
    label: "Challenge Prize",
    type: "number",
    value: "prize_challenge",
    placeholder: "Challenge Prize",
    onChange: "handleChange",
  },
  objective: {
    opportunity_type: ["Innovation Challenges", "Hackathons"],
    type: "textarea",
    componentclass: "textarea",
    label: "Objective",
    value: "objective",
    placeholder: "Please provide a description of the objective",
    name: "objective",
    onChange: "handleChange",
  },
};

const industry_list = [
  "Agriculture",
  "AI/ML",
  "Blockchain",
  "Cannabis",
  "Construction",
  "Data",
  "E-Commerce",
  "Education",
  "Energy",
  "Enterprise Software",
  "Environmental",
  "Fashion",
  "Fintech",
  "Fitness",
  "Food",
  "Gaming",
  "Healthcare",
  "Hospitality",
  "IoT",
  "Legal",
  "Lifestyle",
  "Manufacturing",
  "Marketing",
  "Media",
  "Photography",
  "PropTech",
  "Real Estate",
  "Retail",
  "Robotics",
  "Security",
  "Social Media",
  "Space",
  "Sports",
  "Telecom",
  "Transportation",
  "Travel",
];

const stage_list = [
  {
    value: 1.1,
    text: "Pre-Seed",
  },
  {
    value: 1.2,
    text: "Seed",
  },
  {
    value: 2.1,
    text: "Series A",
  },
  {
    value: 2.2,
    text: "Series B",
  },
  {
    value: 2.3,
    text: "Series C",
  },
  {
    value: 3,
    text: "IPO",
  },
];

module.exports = {
  industry_list,
  stage_list,
  business_type,
  country_list,
  opportunity_type,
  opportunity_fields_values,
  vertical_list,
  create_opp_questions,
};
