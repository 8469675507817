import React, { useState, useEffect } from "react";
import {
  document_labels,
  file_type_mapping,
} from "../../../../boilerplate/list.json";
import axios from "axios";
import "./submit_opportunity.css";

const MAX_DOCUMENTS = 10;

const mandatoryDocumentLabels = ["Pitch Deck"];

function UploadDocuments({ companyInfo, user, nextStep, files, setFiles }) {
  const [selectedLabel, setSelectedLabel] = useState("");
  const [otherLabel, setOtherLabel] = useState("");
  const [documents, setDocuments] = useState([]);
  const [selectedMandatory, setSelectedMandatory] = useState({});
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [filesToDelete, setFilesToDelete] = useState([]);

  useEffect(() => {
    fetchCompanyFiles(companyInfo._id);
  }, [companyInfo]);

  const fetchCompanyFiles = async (companyId) => {
    try {
      const response = await axios.get(
        `/fetch_company_files?company_id=${companyId}`,
      );
      console.log(response.data);
      setFiles(response.data.files);
      setDocuments(
        response.data.files.map((file) => ({
          file: null,
          label: getLabelFromFileType(file.file_type),
          existing: true,
          uploaded: true,
          _id: file._id,
          file_url: file.file_url,
          file_type: file.file_type,
        })),
      );
    } catch (error) {
      console.error("Error fetching company files:", error);
    }
  };

  const getLabelFromFileType = (fileType) => {
    return (
      Object.keys(file_type_mapping).find(
        (key) => file_type_mapping[key] === fileType,
      ) || fileType
    );
  };

  const handleLabelChange = (event) => {
    setSelectedLabel(event.target.value);
    if (event.target.value !== "Other") {
      setOtherLabel("");
    }
  };

  const handleOtherLabelChange = (event) => {
    setOtherLabel(event.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const label = selectedLabel === "Other" ? otherLabel : selectedLabel;
    const fileType =
      selectedLabel === "Other"
        ? otherLabel
        : file_type_mapping[label] || `company ${label}`;

    if (file && label) {
      const duplicate = documents.some((doc) => doc.file_type === fileType);
      if (duplicate) {
        setErrors({
          ...errors,
          duplicate: `Document type ${label} already exists.`,
        });
        return;
      }

      if (documents.length < MAX_DOCUMENTS) {
        const newDocument = {
          file,
          label,
          file_type: fileType,
          existing: false,
          uploaded: false,
        };
        setDocuments([...documents, newDocument]);
        if (mandatoryDocumentLabels.includes(label)) {
          setSelectedMandatory((prev) => ({ ...prev, [label]: true }));
        }
        setSelectedLabel("");
        setOtherLabel("");
        setErrors({ ...errors, duplicate: null });
      } else {
        setErrors({
          ...errors,
          limit: `You can upload a maximum of ${MAX_DOCUMENTS} documents.`,
        });
      }
    } else {
      setErrors({ ...errors, label: "Please select a label." });
    }
  };

  const handleRemoveDocument = (index) => {
    const removedDoc = documents[index];
    const newDocuments = documents.filter((_, i) => i !== index);
    setDocuments(newDocuments);

    if (removedDoc.existing) {
      setFilesToDelete([...filesToDelete, removedDoc]);
    }

    if (mandatoryDocumentLabels.includes(removedDoc.label)) {
      setSelectedMandatory((prev) => ({ ...prev, [removedDoc.label]: false }));
    }

    setErrors({ ...errors, limit: null });
  };

  const uploadFile = async (file, companyId, label, fileType, index) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", label.toLowerCase().replace(/ /g, "_"));
    formData.append("file_type", fileType);
    formData.append("object_ref", "company");
    formData.append("object_ref_value", companyId);

    try {
      const response = await axios.post(
        `/upload_file_secure?company_id=${companyId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      if (response.data.status === "success") {
        console.log(`Updated ${label} url: ${response.data.result.file_url}`);
        // Mark the document as uploaded
        setDocuments((prevDocuments) =>
          prevDocuments.map((doc, idx) =>
            idx === index ? { ...doc, uploaded: true } : doc,
          ),
        );
      } else {
        console.error("Error uploading file:", response.data);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const deleteFile = async (companyId, fileType) => {
    try {
      const response = await axios.delete(
        `/delete_company_file?company_id=${companyId}`,
        {
          data: { file_type: fileType },
        },
      );
      if (response.data.status === "success") {
        console.log(`Deleted ${fileType}`);
      } else {
        console.error("Error deleting file:", response.data);
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const mandatoryMissing = mandatoryDocumentLabels.some(
      (label) =>
        !documents.some(
          (doc) =>
            doc.file_type === file_type_mapping[label] &&
            (doc.uploaded || selectedMandatory[label]),
        ),
    );

    if (mandatoryMissing) {
      setErrors({
        ...errors,
        mandatory: "Please upload all mandatory documents.",
      });
      setSubmitting(false);
      return;
    }

    // Delete files marked for deletion
    for (let file of filesToDelete) {
      await deleteFile(companyInfo._id, file.file_type);
    }

    // Upload new files
    try {
      for (let index = 0; index < documents.length; index++) {
        const document = documents[index];
        if (!document.existing && !document.uploaded) {
          await uploadFile(
            document.file,
            companyInfo._id,
            document.label,
            document.file_type,
            index,
          );
        }
      }
      setSubmitting(false);
      nextStep();
    } catch (error) {
      console.error("Error submitting documents:", error);
      setSubmitting(false);
    }
  };

  const isMandatoryUploaded = (label) => {
    return documents.some(
      (doc) => doc.file_type === file_type_mapping[label] && doc.uploaded,
    );
  };

  return (
    <div className="opportunity-submission-card">
      <h1>Upload Documents</h1>
      <div className="upload-container">
        <div className="mandatory-checklist">
          <h3>Mandatory Documents</h3>
          <ul>
            {mandatoryDocumentLabels.map((label) => (
              <li key={label}>
                <input
                  type="checkbox"
                  checked={
                    selectedMandatory[label] || isMandatoryUploaded(label)
                  }
                  readOnly
                />
                {label}
              </li>
            ))}
          </ul>
        </div>
        <div className="upload-section">
          <label htmlFor="label-select">Select Document Type:</label>
          <select
            id="label-select"
            className="upload-select"
            value={selectedLabel}
            onChange={handleLabelChange}
            disabled={documents.length >= MAX_DOCUMENTS || submitting}
          >
            <option value="" disabled>
              Select a label
            </option>
            {document_labels.map((label) => (
              <option key={label} value={label}>
                {label}
              </option>
            ))}
          </select>
          {errors.label && <span className="error">{errors.label}</span>}
          {selectedLabel === "Other" && (
            <input
              type="text"
              value={otherLabel}
              onChange={handleOtherLabelChange}
              placeholder="Enter label"
            />
          )}
          <input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            disabled={documents.length >= MAX_DOCUMENTS || submitting}
          />
          <label
            htmlFor="file-upload"
            className={`custom-file-upload ${submitting ? "disabled" : ""}`}
          >
            Upload Document
          </label>
          {errors.limit && <span className="error">{errors.limit}</span>}
          {errors.duplicate && (
            <span className="error">{errors.duplicate}</span>
          )}
        </div>
      </div>
      <hr style={{ margin: "20px 0" }} />
      <h3>
        Uploaded Documents ({documents.length}/{MAX_DOCUMENTS})
      </h3>
      <div className="upload-list">
        {documents.length > 0 ? (
          documents.map((doc, index) => (
            <div key={index} className="upload-item">
              <span className="chip">{doc.label}</span>
              {doc.file ? (
                <span className="file-name">{doc.file.name}</span>
              ) : (
                <span className="file-name">Uploaded</span>
              )}
              {doc.uploaded && <span className="upload-tick">✔</span>}
              {!submitting && (
                <span
                  className="upload-remove-button"
                  onClick={() => handleRemoveDocument(index)}
                >
                  ✖
                </span>
              )}
            </div>
          ))
        ) : (
          <p>No documents uploaded yet.</p>
        )}
      </div>
      {errors.mandatory && <span className="error">{errors.mandatory}</span>}
      <button onClick={handleSubmit} disabled={submitting}>
        {submitting ? "Submitting..." : "Submit"}
      </button>
    </div>
  );
}

export default UploadDocuments;
