import React from 'react';

import './_M-sideDrawer.scss'

const sideDrawer = props => {

  let drawerclasses = 'side-drawer';

  if (props.show) {
    drawerclasses = 'side-drawer open';
  }
  return (
    <nav className={ drawerclasses }>
      <ul>
        <ul>
          <li>
            <a href="/opportunities">Opportunities</a>
          </li>
          <li>
            <a href="/explore">Explore</a>
          </li>
          <li>
            <a href="http://startupfuel.tv/services/">Startup Services</a>
          </li>
        </ul>
      </ul>
    </nav>
  )
}




export default sideDrawer;