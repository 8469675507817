import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AragonBodyPart.css";
import AragonCards from "./AragonCards/AragonCards";
import URL from "url-parse";
import pbfavicon from "../../../../../img/pblogo.png";

const AragonBodyPart = (props) => {
  const [data, setData] = useState({
    dealsReviewed: 0,
    investmentsMade: 0,
    totalReviews: 0,
    numberOfPortfolioCompanies: 0,
  });

  const [opportunity, setOpportunity] = useState({
    opportunity_name: "",
    credits: 0,
  });

  useEffect(() => {
    const url = new URL(window.location.href, true);
    const opportunityId = url.query.opportunity_id;

    // Fetch submitted companies for opportunity
    axios
      .get(
        `fetch_submitted_companies_for_opportunity?opportunity_id=${opportunityId}`,
      )
      .then((res) => {
        let dealsReviewedCount = 0;
        let investmentsMadeCount = 0;
        let totalReviewsCount = 0;
        let companies = res.data.full_company_list_with_flags;

        companies.map((company) => {
          if (company.reviews.length > 0) {
            dealsReviewedCount++;
            totalReviewsCount += company.reviews.length;
          }
          if (company.application_status === "offer") {
            investmentsMadeCount++;
          }
        });
        setData({
          dealsReviewed: dealsReviewedCount,
          investmentsMade: investmentsMadeCount,
          totalReviews: totalReviewsCount,
          numberOfPortfolioCompanies: companies.length,
        });
      });

    // Fetch opportunity details
    axios
      .get(`fetch_opportunity?opportunity_id=${opportunityId}`)
      .then((res) => {
        setOpportunity({
          opportunity_name: res.data.result.opportunity_name,
          credits: res.data.result.credits,
        });
      });
  }, []);

  return (
    <div>
      <div className="__header">
        <div className="px-3 pb_page_title">
          <p className="text-xl font-sans font-bold">Dashboard</p>
        </div>
      </div>
      <div className="AragonBodyPart">
        <div className="bento flex">
          <div className="flex rounded-lg">
            <img
              className="pb-welcome-img pr-2  rounded-lg"
              src={pbfavicon}
            ></img>
            <div className="pb-welcome-msg pr-4">
              <h2 className="pt-2">
                Welcome to {opportunity.opportunity_name}
              </h2>
              <p className="text-wrap">AI Credits: {opportunity.credits}</p>
            </div>
          </div>
        </div>
        <div className="AragonFlex AragonCardsParentMain">
          <div className="AragonCardsMR AragonCardsParent">
            <AragonCards
              title="TOTAL DEALS"
              numbers={data.numberOfPortfolioCompanies}
              className="ArgonDashboard orangeGre"
              icon="bar_chart"
              className2="AragonCardsMR"
              fallbackText=""
              fallbackUrl="/funding"
              fallbackUrlText="add a deal"
            />
            <AragonCards
              title="DEALS REVIEWED"
              numbers={data.dealsReviewed}
              className="orangeGre2"
              icon="pie_chart"
              className2="AragonCardsML"
            />
          </div>
          <div className="AragonCardsML AragonCardsParent">
            <AragonCards
              title="TOTAL REVIEWS"
              numbers={data.totalReviews}
              className="tealGre"
              icon="star"
              className2="AragonCardsMR"
            />
            <AragonCards
              title="OFFERS MADE"
              numbers={data.investmentsMade}
              className="blueGre"
              icon="money"
              className2="AragonCardsML"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AragonBodyPart;
