import React from "react";
import { Link } from "react-router-dom";
import { NavItem } from "reactstrap";

const SettingsNavigation = () => {
  const url = new URL(window.location.href);
  const opportunity_id = url.searchParams.get("opportunity_id");

  const isActive = (path) => window.location.pathname.includes(path);

  const activeStyle = {
    backgroundColor: "#C49733",
    color: "#fff",
    fontWeight: "bold",
    borderRadius: "8px",
    padding: "10px 15px",
    display: "flex",
    alignItems: "center",
  };

  const navItemStyle = {
    padding: "10px 15px",
    display: "flex",
    alignItems: "center",
    color: "#666",
    borderRadius: "8px",
    textDecoration: "none",
  };

  const navIconStyle = {
    marginRight: "10px",
    fontSize: "24px",
  };

  const navContainerStyle = {
    borderRadius: "12px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div className="__pb_settings_navigation" style={navContainerStyle}>
      <NavItem
        className="__pb-setting-nav-item"
        tag={Link}
        to={`/applicant_room_settings_judges?opportunity_id=${opportunity_id}`}
        style={
          isActive("applicant_room_settings_judges")
            ? activeStyle
            : navItemStyle
        }
      >
        <i className="material-icons" style={navIconStyle}>
          group
        </i>
        <span className="__pb_settings_navigation_text">Team Settings</span>
      </NavItem>
      <NavItem
        className="__pb-setting-nav-item"
        tag={Link}
        to={`/applicant_room_settings_reviews?opportunity_id=${opportunity_id}`}
        style={
          isActive("applicant_room_settings_reviews")
            ? activeStyle
            : navItemStyle
        }
      >
        <i className="material-icons" style={navIconStyle}>
          star
        </i>
        <span className="__pb_settings_navigation_text">Review Criteria</span>
      </NavItem>
      <NavItem
        className="__pb-setting-nav-item"
        tag={Link}
        to={`/applicant_room_settings_applicants?opportunity_id=${opportunity_id}`}
        style={
          isActive("applicant_room_settings_applicants")
            ? activeStyle
            : navItemStyle
        }
      >
        <i className="material-icons" style={navIconStyle}>
          list
        </i>
        <span className="__pb_settings_navigation_text">Portal Form</span>
      </NavItem>
      <NavItem
        className="__pb-setting-nav-item"
        tag={Link}
        to={`/applicant_room_settings_visibility?opportunity_id=${opportunity_id}`}
        style={
          isActive("applicant_room_settings_visibility")
            ? activeStyle
            : navItemStyle
        }
      >
        <i className="material-icons" style={navIconStyle}>
          visibility
        </i>
        <span className="__pb_settings_navigation_text">Portal Visibility</span>
      </NavItem>
    </div>
  );
};

export default SettingsNavigation;
