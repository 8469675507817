import React from "react";
import "./dealInfo/dealInfo.css";

export default function Submenu({ activeItem, setActiveItem, menuItems }) {
  return (
    <div className="review-submenu">
      <ul className="review-submenu-list">
        {menuItems.map((item) => (
          <li
            key={item}
            onClick={() => setActiveItem(item)}
            className={activeItem === item ? "active" : ""}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}
