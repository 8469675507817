/* (2019-06-11) - This is the settings page of the SIR/Application room, it
allows you to configure various settings for the SIR. A lot of the logic is
handled on other pages, this mainly just accepts the checkbox.
*/
import React, { Component } from "react";
import axios from "axios";
import URL from "url-parse";
import { Success_Popup } from "../../../components/popups";
import Checkbox from "@material-ui/core/Checkbox";
import ArgNav from "../argNav";
import { Switch } from "@material-ui/core";
import { NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import "./index.scss";
import Navigation from "../../../components/navbar_pb";
import SettingsNavigation from "./settings-sidenav";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      judges: [1],
      judges_can_register: false,
      users_can_see_feedback: false,
      additional_review_options: true,
      additional_review_criteria: [],
      temp_value: "",
      success_msg: "Saved!",
      show_success_popup: false,
      judges_get_email: false,
      opportunity_private: false,
      limit_number_of_reviews: false,
      number_of_reviews: null,
      has_applicant_registration_email: false,
      applicant_registration_email: {
        header: "Thank you for applying!",
        body: `We appreciate you taking the time to apply to our application.
              We will get back to your shortly regarding how you did.
              `,
      },
      opportunity_form_modify: false,
      custom_text_q1: "",
      custom_text_q2: "",
      opportunity_form: [],
      questions: [],
      question: "",
      answer: "",
      editingQuestionId: false,
      questinID: "",
      disableButton: false,
    };
  }
  componentDidMount() {
    this.fetchQuestionApi();
  }

  fetchQuestionApi = () => {
    this.setState({ disableButton: true });
    axios.get(`/get_questions_all`).then((response) => {
      console.log("response 70", response);
      this.setState({
        questions: response?.data?.questions,
        disableButton: false,
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        judges: this.props.judges,
      });
    }
  }

  handleAddQuestion = (event) => {
    event.preventDefault();
    const { question } = this.state;
    console.log("question 240", question);
    if (question) {
      axios
        .post(`/add_question_ai`, {
          question: question,
        })
        .then((response) => {
          console.log("response 245", response);
          if (response) {
            this.setState({ question: "" });
            this.fetchQuestionApi();
          }
        });
    }
  };

  handleDeleteQuestion = (id) => {
    axios
      .delete(`/questions/${id}`)
      .then((response) => {
        if (response) {
          this.fetchQuestionApi();
        }
      })
      .catch((error) => {
        console.error("Error deleting question:", error);
      });
  };

  handleQuestionChange = (event) => {
    this.setState({ question: event.target.value });
  };

  handleSummarize = async (questionId, questionText) => {
    axios
      .post(`/fetch_summarize`, {
        question: questionText,
      })
      .then((response) => {
        console.log("response 142,", response);
        const newAnswers = { ...this.state.answer };
        newAnswers[questionId] = response.data.result;
        this.setState({ answer: newAnswers });
      });
  };

  handleEditQuestion = (quetext, id) => {
    this.setState({
      question: quetext,
      editingQuestionId: true,
      questinID: id,
    });
  };

  handleUpdateQuestion = (event) => {
    event.preventDefault();
    const { question, questinID } = this.state;
    axios
      .post(`/questions/${questinID}`, {
        question: question,
      })
      .then((response) => {
        this.fetchQuestionApi();
        this.setState({ question: "" });
        console.log("edit response 142,", response);
      });
  };

  handleEmailChange = (e) => {
    const name = e.target.name;
    this.setState({
      applicant_registration_email: {
        ...this.state.applicant_registration_email,
        [name]: e.target.value,
      },
    });
  };

  handleNumberReviewsSave = (e) => {
    axios.post(
      `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        judges_can_register: this.state.judges_can_register,
        users_can_see_feedback: this.state.users_can_see_feedback,
        judges_get_email: this.state.judges_get_email,
        limit_number_of_reviews: this.state.limit_number_of_reviews,
        additional_review_criteria: this.state.additional_review_criteria,
        additional_review_options: this.state.additional_review_options,
        number_of_reviews: this.state.number_of_reviews,
        opportunity_private: this.state.opportunity_private,
        opportunity_form_modify: this.state.opportunity_form_modify,
        has_applicant_registration_email:
          this.state.has_applicant_registration_email,
      },
    );
    this.displaySaved();
  };

  handleCheck = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: !this.state[name],
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
            enter_company_website: this.state.enter_company_website,
            enter_pitch_video: this.state.enter_pitch_video,
          },
        );
        this.displaySaved();
      },
    );
  };
  handleCustomForm = (e, key) => {
    const name = e.target.name;
    let target_key = this.state.opportunity_form[key];
    if (
      name === "display" &&
      this.state.opportunity_form[key]["display"] === true
    ) {
      let obj = {
        ...this.state.opportunity_form[key],
        ["required"]: false,
        ["display"]: false,
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    } else {
      let obj = {
        ...this.state.opportunity_form[key],
        [name]: !this.state.opportunity_form[key][name],
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    }
  };
  handleFormSave = (e) => {
    axios.post(
      `/edit_opportunity_form?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        opportunity_form: this.state.opportunity_form,
      },
    );
    this.displaySaved();
  };
  handleEmailSave = (e) => {
    axios.post(
      `/edit_opportunity_email?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        applicant_registration_email: {
          header: this.state.applicant_registration_email.header,
          body: this.state.applicant_registration_email.body,
        },
      },
    );
    this.displaySaved();
  };

  handleSave = (e) => {
    axios.post(
      `/create_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        custom_text_q1: this.state.custom_text_q1,
        custom_text_q2: this.state.custom_text_q2,
      },
    );
  };

  addCriteria = (e) => {
    this.setState(
      {
        additional_review_criteria: [
          ...this.state.additional_review_criteria,
          this.state.temp_value,
        ],
        temp_value: "",
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
          },
        );
        this.displaySaved();
      },
    );
  };
  deleteCriteria = (e, value) => {
    let newList = this.state.additional_review_criteria.splice(
      this.state.additional_review_criteria.indexOf(value),
      1,
    );
    this.setState(
      {
        additional_review_criteria:
          this.state.additional_review_criteria.filter((x) => x !== value),
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
          },
        );
        this.displaySaved();
        axios.post(
          `/edit_rating_criteria_for_opp?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            additional_review_criteria: this.state.additional_review_criteria,
            value: value,
          },
        );
      },
    );
  };

  render() {
    const {
      questions,
      question,
      answer,
      editingQuestionId,
      disableButton,
      url,
    } = this.state;

    // console.log("answer 286",answer)
    return (
      <div>
        <Navigation />
        <ArgNav
          opportunity_name={this.props.opportunity_name}
          opportunity_id={this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname}
        />
        <div className="_content">
          <React.Fragment>
            <Success_Popup
              show={this.state.show_success_popup}
              alert_message={this.state.success_msg}
            />
            <div className="__application-room-settings">
              <div className="__header  __application-room-padding">
                <h3>Question App</h3>
              </div>
              <div className="__pb_settings_page">
                <SettingsNavigation />
                <div className="__main __application-room-padding">
                  <label>
                    {/* <p className='__settings_text'>add question</p> */}
                    <div className="add-q-main-wraper">
                      <h4>DiligenceGPT</h4>
                      <p>
                        Set custom questions to summarize uploaded pitchdecks
                      </p>
                      <form
                        className="r_faq_form"
                        onSubmit={
                          editingQuestionId
                            ? this.handleUpdateQuestion
                            : this.handleAddQuestion
                        }
                      >
                        <div className="r_faq_question mb-5 border-2">
                          <input
                            type="text"
                            value={question}
                            onChange={this.handleQuestionChange}
                            placeholder="Question"
                          />
                          <button type="submit" className="btn p-3">
                            {editingQuestionId ? "Update" : "Add"}
                          </button>
                        </div>
                      </form>

                      <div>
                        {disableButton ? (
                          <div class="__submission-page">
                            <div class="SocialLoadingIcon">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="128"
                                height="32"
                                viewBox="0 0 128 32"
                              >
                                <path d="M112-0.008c-8.84 0-16 7.16-16 16 0 8.832 7.16 15.992 16 15.992s16-7.16 16-15.992c0-8.84-7.16-16-16-16zM64-0.008c-8.84 0-16 7.16-16 16 0 8.832 7.16 15.992 16 15.992s16-7.16 16-15.992c0-8.84-7.16-16-16-16zM16-0.008c-8.832 0-16 7.16-16 16s7.168 15.992 16 15.992 16-7.16 16-15.992c0-8.84-7.16-16-16-16z"></path>
                              </svg>
                            </div>
                          </div>
                        ) : questions?.length ? (
                          questions.map((question) => (
                            <div className="r_faq_item" key={question.id}>
                              <h5>{question.question}</h5>
                              <p
                                className={`${answer[question._id] ? "answer-add-q" : null}`}
                              >
                                {answer[question._id]}
                              </p>
                              <div className="f_faq_item_action">
                                <button
                                  onClick={() =>
                                    this.handleEditQuestion(
                                      question.question,
                                      question._id,
                                    )
                                  }
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() =>
                                    this.handleDeleteQuestion(question._id)
                                  }
                                >
                                  Delete
                                </button>
                                {/* <button onClick={()=> this.handleSummarize(question._id,question.question)}>Summarize</button> */}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>
                            <h3>Question Not Found</h3>
                          </div>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

export default Page;
