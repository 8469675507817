/* (2019-06-11) - This is the settings page of the SIR/Application room, it
allows you to configure various settings for the SIR. A lot of the logic is
handled on other pages, this mainly just accepts the checkbox.
*/
import React, { Component } from "react";
import axios from "axios";
import URL from "url-parse";
import { FormControl, FormLabel } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { Success_Popup } from "../../../components/popups";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import DeleteIcon from "@material-ui/icons/Close";
import { NavItem } from "reactstrap";
import "./index.scss";
import ArgNav from "../argNav";
import { Link } from "react-router-dom";
import Navigation from "../../../components/navbar_pb";
import SettingsNavigation from "./settings-sidenav";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      judges: [1],
      judges_can_register: false,
      users_can_see_feedback: false,
      additional_review_options: true,
      additional_review_criteria: [],
      temp_value: "",
      success_msg: "Saved!",
      show_success_popup: false,
      judges_get_email: false,
      opportunity_private: false,
      limit_number_of_reviews: false,
      number_of_reviews: null,
      has_applicant_registration_email: false,
      applicant_registration_email: {
        header: "Thank you for applying!",
        body: `We appreciate you taking the time to apply to our application.
              We will get back to your shortly regarding how you did.
              `,
      },
      opportunity_form_modify: false,
      custom_text_q1: "",
      custom_text_q2: "",
      opportunity_form: [],
    };
  }
  componentDidMount() {
    axios
      .get(
        `fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          ...response.data.result,
        });
      });
    axios
      .get(
        `/fetch_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          ...response.data.existing_opportunity_form,
        });
      });
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        judges: this.props.judges,
      });
    }
  }

  displaySaved = () => {
    this.setState(
      {
        show_success_popup: true,
      },
      () => {
        setTimeout(() => this.setState({ show_success_popup: false }), 1500);
      },
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleEmailChange = (e) => {
    const name = e.target.name;
    this.setState({
      applicant_registration_email: {
        ...this.state.applicant_registration_email,
        [name]: e.target.value,
      },
    });
  };
  handleNumberReviewsSave = (e) => {
    axios.post(
      `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        judges_can_register: this.state.judges_can_register,
        users_can_see_feedback: this.state.users_can_see_feedback,
        judges_get_email: this.state.judges_get_email,
        limit_number_of_reviews: this.state.limit_number_of_reviews,
        number_of_reviews: this.state.number_of_reviews,
        opportunity_private: this.state.opportunity_private,
        opportunity_form_modify: this.state.opportunity_form_modify,
        has_applicant_registration_email:
          this.state.has_applicant_registration_email,
      },
    );
    this.displaySaved();
  };

  handleCheck = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: !this.state[name],
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
          },
        );
        this.displaySaved();
      },
    );
  };
  handleCustomForm = (e, key) => {
    const name = e.target.name;
    let target_key = this.state.opportunity_form[key];
    if (
      name === "display" &&
      this.state.opportunity_form[key]["display"] === true
    ) {
      let obj = {
        ...this.state.opportunity_form[key],
        ["required"]: false,
        ["display"]: false,
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    } else {
      let obj = {
        ...this.state.opportunity_form[key],
        [name]: !this.state.opportunity_form[key][name],
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    }
  };
  handleFormSave = (e) => {
    axios.post(
      `/edit_opportunity_form?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        opportunity_form: this.state.opportunity_form,
      },
    );
    this.displaySaved();
  };
  handleEmailSave = (e) => {
    axios.post(
      `/edit_opportunity_email?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        applicant_registration_email: {
          header: this.state.applicant_registration_email.header,
          body: this.state.applicant_registration_email.body,
        },
      },
    );
    this.displaySaved();
  };

  handleSave = (e) => {
    axios.post(
      `/create_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        custom_text_q1: this.state.custom_text_q1,
        custom_text_q2: this.state.custom_text_q2,
      },
    );
  };

  addCriteria = (e) => {
    if (
      this.state.additional_review_criteria === undefined ||
      this.state.additional_review_criteria === null ||
      this.state.additional_review_criteria.length === 0
    ) {
      let updated_array = [this.state.temp_value];
      this.setState(
        {
          additional_review_criteria: updated_array,
          temp_value: "",
        },
        () => {
          axios.post(
            `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
            {
              judges_can_register: this.state.judges_can_register,
              users_can_see_feedback: this.state.users_can_see_feedback,
              judges_get_email: this.state.judges_get_email,
              limit_number_of_reviews: this.state.limit_number_of_reviews,
              opportunity_private: this.state.opportunity_private,
              opportunity_form_modify: this.state.opportunity_form_modify,
              has_applicant_registration_email:
                this.state.has_applicant_registration_email,
              additional_review_options: true,
              additional_review_criteria: this.state.additional_review_criteria,
            },
          );
          this.displaySaved();
        },
      );
    } else {
      this.setState(
        {
          additional_review_criteria: [
            ...this.state.additional_review_criteria,
            this.state.temp_value,
          ],
          temp_value: "",
        },
        () => {
          axios.post(
            `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
            {
              judges_can_register: this.state.judges_can_register,
              users_can_see_feedback: this.state.users_can_see_feedback,
              judges_get_email: this.state.judges_get_email,
              limit_number_of_reviews: this.state.limit_number_of_reviews,
              opportunity_private: this.state.opportunity_private,
              opportunity_form_modify: this.state.opportunity_form_modify,
              has_applicant_registration_email:
                this.state.has_applicant_registration_email,
              additional_review_options: true,
              additional_review_criteria: this.state.additional_review_criteria,
            },
          );
          this.displaySaved();
        },
      );
    }
  };

  deleteCriteria = (e, value) => {
    let newList = this.state.additional_review_criteria.splice(
      this.state.additional_review_criteria.indexOf(value),
      1,
    );
    this.setState(
      {
        additional_review_criteria:
          this.state.additional_review_criteria.filter((x) => x !== value),
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
          },
        );
        this.displaySaved();
        axios.post(
          `/edit_rating_criteria_for_opp?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            additional_review_criteria: this.state.additional_review_criteria,
            value: value,
          },
        );
      },
    );
  };

  render() {
    const { url } = this.state;
    return (
      <div>
        <Navigation />
        <ArgNav
          opportunity_name={this.props.opportunity_name}
          opportunity_id={this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname}
        />
        <div class="_content">
          <React.Fragment>
            <Success_Popup
              show={this.state.show_success_popup}
              alert_message={this.state.success_msg}
            />
            <div className="__application-room-settings">
              <div className="__header __application-room-padding">
                <h3>Review Settings</h3>
              </div>
              <div className="__pb_settings_page">
                <SettingsNavigation />
                <div className="__main __application-room-padding">
                  <h2 style={{ fontSize: "large" }}>
                    The PitchBox comes out of the box with standard review
                    criteria:
                  </h2>
                  <ul>
                    <p className="__pb_review_settings_text">
                      - Idea - indicate how unique the idea, problem, and
                      solution is for the venture
                    </p>
                    <p className="__pb_review_settings_text">
                      - Tech - indicate how strong the technology is currently
                      built{" "}
                    </p>
                    <p className="__pb_review_settings_text">
                      - Marketing - indicate how well the marketing strategy is
                      thought out
                    </p>
                    <p className="__pb_review_settings_text">
                      - Team - indicate the strength of the founders,
                      management, the board and the rest of the team.
                    </p>
                    {this.state.additional_review_criteria !== undefined &&
                      this.state.additional_review_criteria !== null &&
                      this.state.additional_review_criteria.map((val) => (
                        <div>
                          <p>
                            <span className="__pb_review_settings_text">
                              - {val}
                            </span>{" "}
                            <DeleteIcon
                              onClick={(event) =>
                                this.deleteCriteria(event, val)
                              }
                            />
                          </p>
                        </div>
                      ))}
                  </ul>
                  <div>
                    <hr></hr>
                    <span className="__pb_review_settings_text">
                      Enter Custom Review Criteria:
                      <p>
                        By adding custom criteria, it will add another review
                        option for your team to review deals on
                      </p>
                    </span>
                    <FormControl
                      type="text"
                      value={this.state.temp_value}
                      placeholder="Ex. Social Impact, IP, Traction"
                      name="temp_value"
                      onChange={this.handleChange}
                      className="form-control mb-3"
                    />
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.addCriteria}
                    name="showEditOne"
                  >
                    Save
                  </Button>
                  {/* <br /> <br />
                  <label>
                    <Checkbox
                      checked={this.state.limit_number_of_reviews}
                      onChange={(event) => { this.handleCheck(event) }}
                      name="limit_number_of_reviews"
                      color="primary"
                    />
                    <span>Set a max amount of reviews per deal<em>*when max amount is hit, deal is considered reviewed </em></span>
                  </label> */}
                  <div>
                    {this.state.limit_number_of_reviews &&
                      Array.isArray(this.state.judges) && (
                        <div className="____application-room-select-wrapper">
                          <div>
                            How many reviews would you like each company to
                            receive?
                          </div>
                          <div className="__application-room-select">
                            <Select
                              value={this.state.number_of_reviews}
                              onChange={this.handleChange}
                              name="number_of_reviews"
                            >
                              {this.state.judges !== undefined &&
                                this.state.judges.map((number, i) => (
                                  <MenuItem value={i + 1}>{i + 1}</MenuItem>
                                ))}
                            </Select>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handleNumberReviewsSave}
                              name="showEditOne"
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

export default Page;
