import React, { useEffect, useRef, useState } from "react";
import { stage_list } from "../../../../../boilerplate/list.json";
import default_pic from "../../../../../img/default_company_pic.png";
import { TypeAnimation } from "react-type-animation";
import "../tearsheet.css";
import axios from "axios";
import html2pdf from "html2pdf.js";

const EditableText = ({
  value,
  onChange,
  triggerResize,
  showTypingAnimation,
  onAnimationComplete,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const textareaRef = useRef(null);

  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (isEditing) {
      resizeTextarea();
    }
  }, [isEditing, value, triggerResize]);

  return (
    <>
      {isEditing ? (
        <textarea
          ref={textareaRef}
          style={{ width: "100%", height: "auto", overflow: "hidden" }}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            resizeTextarea();
          }}
          onBlur={() => setIsEditing(false)}
          autoFocus
        />
      ) : (
        <div onClick={() => setIsEditing(true)}>
          {value && showTypingAnimation ? (
            <TypeAnimation
              sequence={[
                value,
                20000, // Optional: Add a delay if you want the text to stay for a while before completing
                () => {
                  onAnimationComplete(); // Call the onComplete handler to update the state
                },
              ]}
              wrapper="span"
              cursor={true}
              speed={85}
              repeat={0}
              style={{ whiteSpace: "pre-line" }}
            />
          ) : (
            value || "Click to edit"
          )}
        </div>
      )}
    </>
  );
};

export default function Tearsheet(props) {
  const [content, setContent] = useState({
    Overview: "",
    Problem: "",
    Solution: "",
    Market: "",
    Traction: "",
    Advantage: "",
    KeyHighlights: [],
  });
  const [ocrLoader, setOcrLoader] = useState(false);
  const [triggerResize, setTriggerResize] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // New state for saving
  const [error, setError] = useState("");
  const [companyAvatar, setCompanyAvatar] = useState(default_pic);
  const [showTypingAnimation, setShowTypingAnimation] = useState(false);
  const pdfRef = useRef(null);

  function getStage(value) {
    const stage = stage_list.find((stage) => stage.value === value);
    return stage ? stage.text : "Value not found";
  }

  const handleAnimationComplete = () => {
    setShowTypingAnimation(false); // Set the typing animation flag to false
  };

  const handleGenerate = () => {
    if (props.opportunity_data.credits < 1) {
      setError(
        "You do not have enough credits to generate a Startup Tear Sheet.",
      );
      return;
    }
    const applicationInfo = props.applicationInfo;
    const handleCreditChange = props.handleCreditChange;
    setShowTypingAnimation(true);

    let requestBody = {};

    const hasValidApplicationObjectAnswers =
      applicationInfo &&
      Object.values(applicationInfo).some(
        (answer) => typeof answer === "string" && answer.trim() !== "",
      );

    if (hasValidApplicationObjectAnswers) {
      requestBody.applicationObject = applicationInfo;
    }

    setOcrLoader(true);
    setError(""); // Clear any existing errors

    axios
      .post(
        `/fetch_tearsheet?company_id=${props.company.applicant_company}`,
        requestBody,
      )
      .then((response) => {
        console.log("API response:", response.data);
        if (response.data) {
          setContent(response.data.analysis);
          setTriggerResize((prev) => !prev); // Trigger resize
        }
        handleCreditChange(-1);
        props.updateCompanyData(props.company.applicant_company, {
          TearSheet: response.data.analysis,
        });

        setOcrLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching OCR data:", error);
        setError("Error Generating Tearsheet");
        setOcrLoader(false); // Ensure loader is hidden in case of error
      });
  };

  const handleHighlightChange = (index, newValue) => {
    const newHighlights = [...content.KeyHighlights];
    newHighlights[index] = newValue;
    setContent({ ...content, KeyHighlights: newHighlights });
  };

  const downloadPdf = () => {
    setIsDownloading(true); // Set loader to true when starting the download
    const element = pdfRef.current;

    // Get the exact height of the content in cm
    const heightPx = element.scrollHeight; // Height in pixels
    const heightCM = heightPx / 35.35; // Convert pixels to cm

    const options = {
      filename: props.company.company_name + " Tearsheet | StartupFuel.pdf",
      html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
      jsPDF: {
        orientation: "portrait",
        unit: "cm",
        format: [heightCM, 29.7], // 29.7 cm is the height of an A4 page
      },
    };

    html2pdf()
      .from(element)
      .set(options)
      .save()
      .then(() => setIsDownloading(false)) // Reset loader state after download completes
      .catch((error) => {
        console.error("Error generating PDF:", error);
        setError("Error generating PDF");
        setIsDownloading(false); // Reset loader state in case of error
      });
  };

  const saveContent = () => {
    setIsSaving(true); // Set loader to true when starting the save
    axios
      .post(`/save_tearsheet?company_id=${props.company.applicant_company}`, {
        content: content,
      })
      .then(() => {
        setIsSaving(false); // Reset loader state after save completes
      })
      .catch((error) => {
        console.error("Error saving content:", error);
        setError("Error saving content");
        setIsSaving(false); // Reset loader state in case of error
      });
  };

  const DateCreated = ({ date }) => {
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const options = { year: "numeric", month: "long", day: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);
      const [month, day, year] = formattedDate.split(" "); // Assuming the default 'en-US' format is "Month Day, Year"
      return `${month} ${day.replace(",", "")} ${year}`;
    };

    return <p>Date-created: {formatDate(date)}</p>;
  };

  useEffect(() => {
    const getAvatarUrl = (files) => {
      const avatarFile = files.find(
        (file) => file.file_type === "company avatar",
      );
      return avatarFile ? avatarFile.file_url : default_pic;
    };

    if (props.company && props.company.company_files) {
      const avatarUrl =
        getAvatarUrl(props.company.company_files) + "?" + Date.now();
      fetch(avatarUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const imageUrl = URL.createObjectURL(blob);
          setCompanyAvatar(imageUrl);
        })
        .catch((error) => {
          console.error("Error fetching company avatar:", error);
          setCompanyAvatar(default_pic);
        });
    }
  }, [props.company]);

  useEffect(() => {
    if (props.company && props.company.TearSheet) {
      console.log("TearSheet from props.company:", props.company.TearSheet);
      setContent(props.company.TearSheet);
      setTriggerResize((prev) => !prev); // Trigger resize
    }
  }, [props.company]);

  return (
    <div className="report-container">
      <div className="header">
        <button
          className="button"
          disabled={ocrLoader}
          onClick={handleGenerate}
        >
          {ocrLoader ? "Generating..." : "Generate Startup Tear sheet"}
        </button>
        {!ocrLoader && content.Overview && (
          <>
            <button
              className="button button-secondary"
              disabled={isDownloading}
              onClick={downloadPdf}
            >
              {isDownloading ? "Downloading..." : "Download Tear Sheet"}
            </button>
            <button
              className="button button-secondary"
              disabled={isSaving}
              onClick={saveContent}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </>
        )}
      </div>

      {error && <p className="error">{error}</p>}
      {ocrLoader && (
        <p>Generating Startup Tear sheet. This may take a minute.</p>
      )}

      {!ocrLoader && content.Overview && (
        <div ref={pdfRef} className="pdf-content">
          <div className="company-overview">
            <div className="company-info">
              <div>
                <h2 className="tearsheet-title">Startup Tear sheet</h2>
                Investment Opportunity | Powered by DiligenceGPT
                <p>
                  {props.company.company_name}
                  {props.company.company_website && (
                    <a
                      href={
                        props.company.company_website.startsWith("http")
                          ? props.company.company_website
                          : `http://${props.company.company_website}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      | {props.company.company_website}
                    </a>
                  )}
                </p>
              </div>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/startupfuel-logo.png`}
                  alt="StartupFuel Logo"
                  style={{ height: 50 }}
                />
                <p>Venture Ratings Agency</p>
                <DateCreated date={content.DateCreated} />
              </div>
            </div>
            <div className="tearsheet-section">
              <div className="company-info">
                <div className="company-details">
                  <img
                    src={companyAvatar}
                    alt={`${props.company.company_name} Avatar`}
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
                <div className="company-details">
                  <div>
                    Founded
                    <p style={{ fontWeight: "bold" }}>
                      {props.company.founded_year
                        ? props.company.founded_year
                        : "-"}
                    </p>
                  </div>
                  <div>
                    Location
                    <p style={{ fontWeight: "bold" }}>
                      {props.company.country}
                    </p>
                  </div>
                </div>
                <div className="company-details">
                  <div>
                    Business Model(s)
                    <p style={{ fontWeight: "bold" }}>
                      {props.company.business_model}
                    </p>
                  </div>
                  <div>
                    Primary Industry
                    <p style={{ fontWeight: "bold" }}>
                      {props.company.industry[0]}
                    </p>
                  </div>
                </div>
                <div className="company-details">
                  <div>
                    Business Stage
                    <p style={{ fontWeight: "bold" }}>
                      {getStage(props.company.stage)}
                    </p>
                  </div>
                  <div>
                    Business Type
                    <p style={{ fontWeight: "bold" }}>
                      {props.company.company_type[0]}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "20px" }}>
            <div className="business-overview">
              <h2>Business Overview</h2>
              <EditableText
                value={content.Overview}
                onChange={(value) =>
                  setContent({ ...content, Overview: value })
                }
                triggerResize={triggerResize}
                showTypingAnimation={showTypingAnimation}
                onAnimationComplete={handleAnimationComplete}
              />
              <span style={{ fontWeight: "bold" }}>Problem:</span>
              <EditableText
                value={content.Problem}
                onChange={(value) => setContent({ ...content, Problem: value })}
                triggerResize={triggerResize}
                showTypingAnimation={showTypingAnimation}
                onAnimationComplete={handleAnimationComplete}
              />
              <span style={{ fontWeight: "bold" }}>Solution:</span>
              <EditableText
                value={content.Solution}
                onChange={(value) =>
                  setContent({ ...content, Solution: value })
                }
                triggerResize={triggerResize}
                showTypingAnimation={showTypingAnimation}
                onAnimationComplete={handleAnimationComplete}
              />
              <span style={{ fontWeight: "bold" }}>Market:</span>
              <EditableText
                value={content.Market}
                onChange={(value) => setContent({ ...content, Market: value })}
                triggerResize={triggerResize}
                showTypingAnimation={showTypingAnimation}
                onAnimationComplete={handleAnimationComplete}
              />
              <span style={{ fontWeight: "bold" }}>Traction:</span>
              <EditableText
                value={content.Traction}
                onChange={(value) =>
                  setContent({ ...content, Traction: value })
                }
                triggerResize={triggerResize}
                showTypingAnimation={showTypingAnimation}
                onAnimationComplete={handleAnimationComplete}
              />
              <span style={{ fontWeight: "bold" }}>Advantage:</span>
              <EditableText
                value={content.Advantage}
                onChange={(value) =>
                  setContent({ ...content, Advantage: value })
                }
                triggerResize={triggerResize}
                showTypingAnimation={showTypingAnimation}
                onAnimationComplete={handleAnimationComplete}
              />
            </div>
            <div className="key-highlights">
              <h2>Key Highlights</h2>
              <ul>
                {content.KeyHighlights.map((highlight, index) => (
                  <li key={index}>
                    <EditableText
                      value={highlight}
                      onChange={(value) => handleHighlightChange(index, value)}
                      triggerResize={triggerResize}
                      showTypingAnimation={showTypingAnimation}
                      onAnimationComplete={handleAnimationComplete}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <hr
              style={{
                margin: "20px 0",
                border: "none",
                borderTop: "2px solid #000",
                width: "100%",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>
                Confidential | Contact us for info on how this was derived:
                diligence@startupfuel.com
              </p>
              <img
                src={require("../../../../../img/favicon.png")}
                style={{ width: 40 }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
